"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vuex = require("vuex");

var _errorParse = require("@/utils/error-parse");

var _elementUi = require("element-ui");

var _i18n = _interopRequireDefault(require("@/i18n"));

var _purchaseRequest = require("@/api/purchaseRequest");

var _store = require("@/api/store");

var _addItemDialog = _interopRequireDefault(require("./add-item-dialog"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    AddItemDialog: _addItemDialog.default
  },

  data() {
    return {
      loading: false,
      labelPosition: window.innerWidth > 690 ? 'left' : 'top',
      stores: '',
      materials: '',
      createMode: true,
      dialogFormVisible: false,
      purchaseRequest: {
        id: '',
        status: 'Drafted',
        storeId: null,
        remark: '',
        items: []
      },
      disabled: 0,
      buttonText: _i18n.default.t('PurchaseRequests.Save'),
      buttonSubmit: _i18n.default.t('PurchaseRequests.Submit'),
      buttonDelete: _i18n.default.t('PurchaseRequests.Delete'),
      buttonUnsubmit: _i18n.default.t('PurchaseRequests.Unsubmit'),
      buttonConfirm: _i18n.default.t('PurchaseRequests.Confirm'),
      buttonReject: _i18n.default.t('PurchaseRequests.Reject'),
      buttonComplete: _i18n.default.t('PurchaseRequests.Complete'),
      buttonDownload: _i18n.default.t('PurchaseRequests.Download'),
      buttonGeneratePO: _i18n.default.t('PurchaseRequests.GeneratePO'),
      rules: {
        status: {
          required: true,
          message: _i18n.default.t('PurchaseRequests.RequiredMessage'),
          trigger: 'change'
        },
        storeId: {
          required: true,
          message: _i18n.default.t('PurchaseRequests.RequiredMessage')
        }
      },
      options: [],
      roleNames: []
    };
  },

  computed: { ...(0, _vuex.mapGetters)(['user', 'roles']),
    canConfirm: {
      get() {
        return this.roleNames && (this.roleNames.includes('Admin') || this.roleNames.includes('Procurement Manager'));
      }

    },
    canUnsubmit: {
      get() {
        return this.user && this.purchaseRequest && (!this.purchaseRequest.submittedBy || this.user.uuid === this.purchaseRequest.submittedBy.uuid);
      }

    },
    windowWidth: {
      get() {
        return window.innerWidth;
      }

    },
    store: {
      get() {
        return this.stores && this.purchaseRequest.storeId ? this.stores.find(store => store.uuid === this.purchaseRequest.storeId) : null;
      }

    },
    drafted: {
      get() {
        return this.purchaseRequest && this.purchaseRequest.status === 'Drafted';
      }

    },
    submitted: {
      get() {
        return this.purchaseRequest && this.purchaseRequest.status === 'Submitted';
      }

    },
    confirmed: {
      get() {
        return this.purchaseRequest && this.purchaseRequest.status === 'Confirmed';
      }

    },
    rejected: {
      get() {
        return this.purchaseRequest && this.purchaseRequest.status === 'Rejected';
      }

    },
    completed: {
      get() {
        return this.purchaseRequest && this.purchaseRequest.status === 'Completed';
      }

    }
  },
  watch: {
    windowWidth(newWidth, oldWidth) {
      if (newWidth < 690 && oldWidth > 690) {
        this.labelPosition = 'top';
      } else if (newWidth > 690 && oldWidth < 690) {
        this.labelPosition = 'left';
      }
    }

  },

  async created() {
    let promise = Promise.resolve();

    if (this.$route.params.id) {
      // this.buttonText = 'Save'
      this.disabled = 1;
      promise = this.fetchData();
      this.createMode = false;
    } else {
      this.createMode = true; // this.buttonText = 'Save'
    }

    promise.then(() => {
      if (this.submitted || this.confirmed || this.rejected || this.completed) {
        this.stores = [this.purchaseRequest.store];
      } else {
        (0, _store.getStores)({
          limit: 999
        }).then(response => {
          this.stores = response.data;
        });
      }
    });
    this.roleNames = this.roles.map(role => role.name);
  },

  methods: {
    handleAddItemDialogComplete(rawMaterials) {
      rawMaterials.forEach(rawMaterial => {
        const item = {};
        item.rawMaterial = rawMaterial;
        item.qty = 0;
        item.remark = null;
        this.purchaseRequest.items.push(item);
        this.dialogFormVisible = false;
      });
    },

    addRow() {
      this.dialogFormVisible = true;
    },

    fetchData() {
      this.loading = true;
      return (0, _purchaseRequest.getPurchaseRequest)(this.$route.params.id).then(response => {
        this.purchaseRequest = response.data;
        this.purchaseRequest.storeId = response.data.store.uuid;
      }).finally(() => {
        this.loading = false;
      });
    },

    handleDelete(index) {
      this.purchaseRequest.items.splice(index, 1);
    },

    onSubmit() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return false;
        }

        this.loading = true;

        if (this.$route.params.id) {
          (0, _purchaseRequest.editPurchaseRequest)(this.purchaseRequest).then(response => {
            (0, _elementUi.Message)({
              message: _i18n.default.t('PurchaseRequests.UpdatedPurchaseRequests'),
              type: 'success',
              duration: 5 * 1000
            });
            this.$router.go();
          }).catch(err => {
            (0, _elementUi.Message)({
              dangerouslyUseHTMLString: true,
              message: (0, _errorParse.parseError)(err) || _i18n.default.t('PurchaseRequests.UpdateFailed'),
              type: 'error',
              duration: 5 * 1000
            }); // console.log(err)

            this.loading = false;
          });
        } else {
          (0, _purchaseRequest.createPurchaseRequest)(this.purchaseRequest).then(response => {
            (0, _elementUi.Message)({
              message: _i18n.default.t('PurchaseRequests.CreatedPurchaseRequests'),
              type: 'success',
              duration: 5 * 1000
            });
            this.$router.push(`/purchase-requests/edit/` + response.data.uuid);
          }).catch(err => {
            (0, _elementUi.Message)({
              dangerouslyUseHTMLString: true,
              message: (0, _errorParse.parseError)(err) || _i18n.default.t('PurchaseRequests.CreateFailed'),
              type: 'error',
              duration: 5 * 1000
            });
            this.loading = false;
          });
        }
      });
    },

    deletePurchaseRequest() {
      _elementUi.MessageBox.confirm(_i18n.default.t('PurchaseRequests.ConfirmDelete'), 'Confirm', {
        confirmButtonText: _i18n.default.t('PurchaseRequests.Delete'),
        cancelButtonText: _i18n.default.t('PurchaseRequests.Cancel'),
        type: 'warning'
      }).then(() => {
        return (0, _purchaseRequest.deletePurchaseRequest)(this.purchaseRequest.uuid).then(() => {
          (0, _elementUi.Message)({
            message: _i18n.default.t('PurchaseRequests.RecordDeleted'),
            type: 'success',
            duration: 5 * 1000
          });
          this.$router.push(`/purchase-requests`);
        }).catch(err => {
          (0, _elementUi.Message)({
            dangerouslyUseHTMLString: true,
            message: (0, _errorParse.parseError)(err) || _i18n.default.t('PurchaseRequests.DeleteFailed'),
            type: 'error',
            duration: 5 * 1000
          });
        });
      }).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },

    submitPurchaseRequest() {
      _elementUi.MessageBox.confirm(_i18n.default.t('PurchaseRequests.ConfirmSubmitRequest'), 'Confirm', {
        confirmButtonText: _i18n.default.t('PurchaseRequests.Submit'),
        cancelButtonText: _i18n.default.t('PurchaseRequests.Cancel'),
        type: 'warning'
      }).then(() => {
        return (0, _purchaseRequest.editPurchaseRequest)(this.purchaseRequest).then(() => {
          return (0, _purchaseRequest.submitPurchaseRequest)(this.purchaseRequest.uuid).then(() => {
            (0, _elementUi.Message)({
              message: _i18n.default.t('PurchaseRequests.RequestSubmitted'),
              type: 'success',
              duration: 5 * 1000
            });
            this.$router.go();
          }).catch(err => {
            (0, _elementUi.Message)({
              dangerouslyUseHTMLString: true,
              message: (0, _errorParse.parseError)(err) || _i18n.default.t('PurchaseRequests.SubmitRequestFailed'),
              type: 'error',
              duration: 5 * 1000
            });
          });
        }).catch(err => {
          (0, _elementUi.Message)({
            dangerouslyUseHTMLString: true,
            message: (0, _errorParse.parseError)(err) || _i18n.default.t('PurchaseRequests.SubmitRequestFailed'),
            type: 'error',
            duration: 5 * 1000
          });
        });
      }).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },

    unsubmitPurchaseRequest() {
      return _elementUi.MessageBox.confirm(_i18n.default.t('PurchaseRequests.ConfirmUnsubmitRequest'), 'Confirm', {
        confirmButtonText: _i18n.default.t('PurchaseRequests.UnSubmit'),
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        return (0, _purchaseRequest.unsubmitPurchaseRequest)(this.purchaseRequest.uuid).then(() => {
          (0, _elementUi.Message)({
            message: _i18n.default.t('PurchaseRequests.RequestSubmitted'),
            type: 'success',
            duration: 5 * 1000
          });
          this.$router.go();
        }).catch(err => {
          (0, _elementUi.Message)({
            dangerouslyUseHTMLString: true,
            message: (0, _errorParse.parseError)(err) || _i18n.default.t('PurchaseRequests.SubmitRequestFailed'),
            type: 'error',
            duration: 5 * 1000
          });
        });
      }).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },

    confirmPurchaseRequest() {
      return _elementUi.MessageBox.confirm(_i18n.default.t('PurchaseRequests.ConfirmPurchaseRequest'), 'Confirm', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        return (0, _purchaseRequest.confirmPurchaseRequest)(this.purchaseRequest.uuid).then(() => {
          (0, _elementUi.Message)({
            message: _i18n.default.t('PurchaseRequests.RequestConfirmed'),
            type: 'success',
            duration: 5 * 1000
          });
          this.$router.push('/purchase-requests/confirmed');
        }).catch(err => {
          (0, _elementUi.Message)({
            dangerouslyUseHTMLString: true,
            message: (0, _errorParse.parseError)(err) || _i18n.default.t('PurchaseRequests.ConfirmRequestFailed'),
            type: 'error',
            duration: 5 * 1000
          });
        });
      }).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },

    rejectPurchaseRequest() {
      return _elementUi.MessageBox.confirm(_i18n.default.t('PurchaseRequests.ConfirmRejectRequest'), 'Confirm', {
        confirmButtonText: _i18n.default.t('PurchaseRequests.Reject'),
        cancelButtonText: _i18n.default.t('PurchaseRequests.Cancel'),
        type: 'warning'
      }).then(() => {
        return (0, _purchaseRequest.rejectPurchaseRequest)(this.purchaseRequest.uuid).then(() => {
          (0, _elementUi.Message)({
            message: _i18n.default.t('PurchaseRequests.RequestRejected'),
            type: 'success',
            duration: 5 * 1000
          });
          this.$router.go();
        }).catch(err => {
          (0, _elementUi.Message)({
            dangerouslyUseHTMLString: true,
            message: (0, _errorParse.parseError)(err) || _i18n.default.t('PurchaseRequests.RejectRequestFailed'),
            type: 'error',
            duration: 5 * 1000
          });
        });
      }).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },

    completePurchaseRequest() {
      return _elementUi.MessageBox.confirm(_i18n.default.t('PurchaseRequests.ConfirmCompleteRequest'), 'Confirm', {
        confirmButtonText: _i18n.default.t('PurchaseRequests.Complete'),
        cancelButtonText: _i18n.default.t('PurchaseRequests.Cancel'),
        type: 'warning'
      }).then(() => {
        return (0, _purchaseRequest.completePurchaseRequest)(this.purchaseRequest.uuid).then(() => {
          (0, _elementUi.Message)({
            message: _i18n.default.t('PurchaseRequests.RequestCompleted'),
            type: 'success',
            duration: 5 * 1000
          });
          this.$router.go();
        }).catch(err => {
          (0, _elementUi.Message)({
            dangerouslyUseHTMLString: true,
            message: (0, _errorParse.parseError)(err) || _i18n.default.t('PurchaseRequests.CompleteRequestFailed'),
            type: 'error',
            duration: 5 * 1000
          });
        });
      }).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },

    generatePurchaseOrder() {
      return _elementUi.MessageBox.confirm(_i18n.default.t('PurchaseRequests.ConfirmGeneratePO'), 'Confirm', {
        confirmButtonText: _i18n.default.t('PurchaseRequests.Generate'),
        cancelButtonText: _i18n.default.t('PurchaseRequests.Cancel'),
        type: 'warning'
      }).then(() => {
        return (0, _purchaseRequest.generatePurchaseOrderFromPurchaseRequest)(this.purchaseRequest.uuid).then(() => {
          (0, _elementUi.Message)({
            message: _i18n.default.t('PurchaseRequests.POGenerated'),
            type: 'success',
            duration: 5 * 1000
          });
          this.$router.go();
        }).catch(err => {
          (0, _elementUi.Message)({
            dangerouslyUseHTMLString: true,
            message: (0, _errorParse.parseError)(err) || _i18n.default.t('PurchaseRequests.GeneratePOFailed'),
            type: 'error',
            duration: 5 * 1000
          });
        });
      }).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },

    onCancel() {
      if (this.purchaseRequest.status === 'Drafted') {
        this.$router.push(`/purchase-requests/drafted`);
      } else if (this.purchaseRequest.status === 'Submitted') {
        this.$router.push(`/purchase-orders/submitted`);
      } else if (this.purchaseRequest.status === 'Confirmed') {
        this.$router.push(`/purchase-orders/submitted`);
      } else if (this.purchaseRequest.status === 'Completed') {
        this.$router.push(`/purchase-requests/completed`);
      } else {
        this.$router.push(`/purchase-requests`);
      }
    },

    downloadPurchaseRequest(id, displayId) {
      (0, _purchaseRequest.downloadPurchaseRequest)(id).then(response => {
        const fileURL = window.URL.createObjectURL(response);
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `${displayId}.pdf`);
        document.body.appendChild(fileLink);
        fileLink.click();
      }).catch(err => {
        console.error(err);
        (0, _elementUi.Message)({
          dangerouslyUseHTMLString: true,
          message: (0, _errorParse.parseError)(err) || _i18n.default.t('PurchaseRequests.DownloadFailed'),
          type: 'error',
          duration: 5 * 1000
        });
      });
    }

  }
};
exports.default = _default;