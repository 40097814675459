"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _auth = require("@/utils/auth");

var _errorParse = require("@/utils/error-parse");

var _elementUi = require("element-ui");

var _message = require("@/api/message");

var _user = require("@/api/user");

var _store = require("@/api/store");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data() {
    return {
      loading: false,
      stores: [],
      roles: [],
      users: [],
      userOptions: [],
      message: {
        id: null,
        title: null,
        content: null,
        attachmentArrayId: []
      },
      status: ['draft', 'public'],
      buttonText: 'Add',
      fileList: [],
      uploadDomain: process.env.VUE_APP_BASE_API,
      uploadHeader: {
        Authorization: 'Bearer ' + (0, _auth.getToken)(),
        'X-Requested-With': 'XMLHttpRequest'
      },
      uploadData: {
        message_id: ''
      }
    };
  },

  computed: {
    device() {
      return this.$store.state.app.device;
    }

  },

  async created() {
    if (this.$route.params.id) {
      this.buttonText = 'Edit';
      this.fetchData();
      (0, _user.getUsers)({
        limit: 999,
        active: 'true'
      }).then(response => {
        this.users = response.data;
        this.userOptions = response.data;
      });
    } else {
      this.buttonText = 'Add';

      if (window.requestIdleCallback) {
        requestIdleCallback(() => {
          (0, _user.getUsers)({
            limit: 999,
            active: 'true'
          }).then(response => {
            this.users = response.data;
            this.userOptions = response.data;
          });
        });
      } else {
        (0, _user.getUsers)({
          limit: 999,
          active: 'true'
        }).then(response => {
          this.users = response.data;
          this.userOptions = response.data;
        });
      }
    }

    if (window.requestIdleCallback) {
      requestIdleCallback(() => {
        (0, _user.getRoles)({
          limit: 999
        }).then(response => {
          this.roles = response.data;
        });
        (0, _store.getStores)({
          limit: 999
        }).then(response => {
          this.stores = response.data;
        });
      });
    } else {
      (0, _user.getRoles)({
        limit: 999
      }).then(response => {
        this.roles = response.data;
      });
      (0, _store.getStores)({
        limit: 999
      }).then(response => {
        this.stores = response.data;
      });
    }
  },

  methods: {
    async fetchData() {
      this.loading = true;
      (0, _message.getMessage)(this.$route.params.id).then(response => {
        this.message = response;
        this.message.attachmentArrayId = [];
        this.fileList = [];
        this.message.attachments && this.message.attachments.length && this.message.attachments.forEach(attachment => {
          const path = attachment.url.toString().substring(attachment.url.lastIndexOf('/') + 1);
          this.fileList.push({
            id: attachment.id,
            name: path,
            url: attachment.url
          });
        });
        this.uploadData.message_id = this.message.id;
      }).catch(error => console.error(error)).finally(() => {
        this.loading = false;
      });
    },

    userFilter(val) {
      this.value = val;

      if (val) {
        this.userOptions = this.users.filter(item => {
          if (!!~item.name.indexOf(val) || !!~item.name.toUpperCase().indexOf(val.toUpperCase())) {
            return true;
          }
        });
      } else {
        this.userOptions = this.users;
      }
    },

    onSubmit() {
      this.loading = true;

      if (this.$route.params.id) {
        (0, _message.editMessage)(this.message).then(() => {
          (0, _elementUi.Message)({
            message: 'Message updated.',
            type: 'success',
            duration: 5 * 1000
          });
          this.$router.push(`/message`);
        }).catch(err => {
          (0, _elementUi.Message)({
            message: (0, _errorParse.parseError)(err) || 'Update failed, please retry.',
            type: 'error',
            duration: 5 * 1000
          }); // console.log(err)

          this.loading = false;
        });
      } else {
        (0, _message.createMessage)(this.message).then(() => {
          (0, _elementUi.Message)({
            dangerouslyUseHTMLString: true,
            message: 'Message created.',
            type: 'success',
            duration: 5 * 1000
          });
          this.$router.push(`/message`);
        }).catch(err => {
          (0, _elementUi.Message)({
            dangerouslyUseHTMLString: true,
            message: (0, _errorParse.parseError)(err) || 'Create failed, please retry.',
            type: 'error',
            duration: 5 * 1000
          });
          this.loading = false;
        });
      }
    },

    onCancel() {
      this.$router.push(`/message`);
    },

    handleRemove(file, fileList) {
      // console.log(file, fileList)
      if (file.id !== undefined) {
        (0, _message.removeMessageAttachment)(file.id);
      } else if (file.response[0].id !== undefined) {
        (0, _message.removeMessageAttachment)(file.response[0].id);
      } else {
        console.error(file);
      }
    },

    handlePreview(file) {
      window.open(file.url);
    },

    handleExceed(files, fileList) {
      this.$message.warning('Maximum upload 3 files.');
    },

    beforeRemove(file, fileList) {
      return this.$confirm(`Do you confirm remove ${file.name}？`);
    },

    handleSuccess(response, file, fileList) {
      this.message.attachmentArrayId.push(response[0].id);

      if (this.$route.params.id) {
        return this.fetchData(true);
      }
    }

  }
};
exports.default = _default;