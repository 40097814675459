"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _errorParse = require("@/utils/error-parse");

var _message = require("@/api/message");

var _elementUi = require("element-ui");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },

  /* filters: {
    statusFilter(status) {
      const statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      }
      return statusMap[status]
    }
  }, */
  data() {
    return {
      list: null,
      listLoading: false,
      listQuery: {
        page: 1,
        perPage: 10,
        listAll: true
      },
      total: 0
    };
  },

  async created() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      this.listLoading = true;
      (0, _message.getList)(this.listQuery).then(response => {
        this.list = response.data;
        this.list.forEach((message, index) => {
          message.id = index + response.meta.from;
        });
        this.total = response.meta.total;
      }).finally(() => {
        this.listLoading = false;
      });
    },

    editMessage(id) {
      this.$router.push(`/messages/edit/${id}`);
    },

    addMessage() {
      this.$router.push(`/messages/add`);
    },

    pushMessage(id) {
      return _elementUi.MessageBox.confirm('Confirm to push message to devices?', 'Confirm', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => (0, _message.pushMessage)(id).then(() => {
        (0, _elementUi.Message)({
          message: 'Push is sent',
          type: 'success',
          duration: 5 * 1000
        });
        return this.fetchData();
      })).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },

    deleteMessage(id) {
      return _elementUi.MessageBox.confirm('Confirm to delete record?', 'Confirm', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => (0, _message.deleteMessage)(id).catch(err => err.status === 404 ? Promise.resolve('already deleted') : (0, _elementUi.Message)({
        dangerouslyUseHTMLString: true,
        message: (0, _errorParse.parseError)(err) || 'Delete failed, please retry.',
        type: 'error',
        duration: 5 * 1000
      })).then(() => {
        (0, _elementUi.Message)({
          message: 'Record deleted',
          type: 'success',
          duration: 5 * 1000
        });
        return this.fetchData();
      })).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    }

  }
};
exports.default = _default;