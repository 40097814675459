var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "action-container" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-circle-plus" },
              on: { click: _vm.addMessage },
            },
            [_vm._v("Add Message")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "No", width: "95" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.id) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Title" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.title) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Content", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.content) + "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Order.Actions") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "warning", icon: "el-icon-edit" },
                        on: {
                          click: function ($event) {
                            return _vm.editMessage(scope.row.uuid)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("Order.Edit")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger", icon: "el-icon-delete" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteMessage(scope.row.uuid)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("Order.Delete")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "default" },
                        on: {
                          click: function ($event) {
                            return _vm.pushMessage(scope.row.uuid)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(scope.row.isSent ? "Re-Send" : "Send Push")
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.perPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "perPage", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }