var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            rules: _vm.rules,
            model: _vm.purchaseRequest,
            "label-width": "auto",
            "label-position": _vm.labelPosition,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "ID", prop: "displayId" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.purchaseRequest.displayId,
                  callback: function ($$v) {
                    _vm.$set(_vm.purchaseRequest, "displayId", $$v)
                  },
                  expression: "purchaseRequest.displayId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("PurchaseRequests.Status"),
                prop: "status",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  value: _vm.purchaseRequest.status
                    ? _vm.$t("PurchaseRequests." + _vm.purchaseRequest.status)
                    : "",
                  disabled: "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("PurchaseRequests.Store"),
                prop: "storeId",
              },
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: _vm.$t("PurchaseRequests.Stores"),
                    disabled:
                      _vm.submitted ||
                      _vm.confirmed ||
                      _vm.rejected ||
                      _vm.completed,
                  },
                  model: {
                    value: _vm.purchaseRequest.storeId,
                    callback: function ($$v) {
                      _vm.$set(_vm.purchaseRequest, "storeId", $$v)
                    },
                    expression: "purchaseRequest.storeId",
                  },
                },
                _vm._l(_vm.stores, function (myStore) {
                  return _c("el-option", {
                    key: myStore.name,
                    attrs: { label: myStore.name, value: myStore.uuid },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.purchaseRequest.storeId
            ? [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("PurchaseRequests.PurchaseRequestItem"),
                    },
                  },
                  [
                    !(_vm.confirmed || _vm.rejected || _vm.completed)
                      ? _c(
                          "div",
                          { staticStyle: { "margin-top": "20px" } },
                          [
                            _c("el-button", { on: { click: _vm.addRow } }, [
                              _vm._v(
                                _vm._s(_vm.$t("PurchaseRequests.AddItem"))
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { data: _vm.purchaseRequest.items },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { label: _vm.$t("route.Supplier") },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.rawMaterial.supplier.name
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    !!scope.row.created_by
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "font-size": "12px",
                                              color: "#909399",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "By: " +
                                                _vm._s(scope.row.created_by)
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "font-size": "12px",
                                              color: "#909399",
                                              "font-style": "italic",
                                            },
                                          },
                                          [_vm._v("Newly Added")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2916107626
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: _vm.$t("PurchaseRequests.Name") },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(scope.row.rawMaterial.sku)
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          scope.row.rawMaterial.nameEng
                                            ? scope.row.rawMaterial.nameEng
                                            : scope.row.rawMaterial.nameChin
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2410229423
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("PurchaseRequests.Qty"),
                            width: "180",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-input-number", {
                                      attrs: {
                                        precision: 2,
                                        min: 0,
                                        size: "small",
                                        disabled:
                                          _vm.confirmed ||
                                          _vm.rejected ||
                                          _vm.completed,
                                      },
                                      model: {
                                        value: scope.row.qty,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "qty", $$v)
                                        },
                                        expression: "scope.row.qty",
                                      },
                                    }),
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(scope.row.rawMaterial.unit)
                                    ),
                                    _c("br"),
                                    _vm._v(" "),
                                    _vm.confirmed || _vm.completed
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "PurchaseRequests.Fullfilled"
                                              )
                                            ) +
                                              ": " +
                                              _vm._s(scope.row.transferredQty) +
                                              " " +
                                              _vm._s(scope.row.rawMaterial.unit)
                                          ),
                                          _c("br"),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.confirmed || _vm.completed
                                      ? _c(
                                          "span",
                                          {
                                            class: {
                                              "color-danger":
                                                scope.row.qty -
                                                  scope.row.transferredQty >
                                                0,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "Outstanding: " +
                                                _vm._s(
                                                  scope.row.qty -
                                                    scope.row.transferredQty
                                                ) +
                                                " " +
                                                _vm._s(
                                                  scope.row.rawMaterial.unit
                                                )
                                            ),
                                            _c("br"),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2178424984
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("PurchaseRequests.Operations"),
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    !(
                                      _vm.confirmed ||
                                      _vm.rejected ||
                                      _vm.completed
                                    )
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              type: "danger",
                                              icon: "el-icon-circle-close",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleDelete(
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "PurchaseRequests.Delete"
                                                )
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3620214239
                          ),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("PurchaseRequests.TargetRestockDate"),
                prop: "targetRestockDate",
              },
            },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  "value-format": "yyyy-MM-dd",
                  placeholder: _vm.$t("PurchaseRequests.PickDate"),
                  disabled: _vm.confirmed || _vm.rejected || _vm.completed,
                },
                model: {
                  value: _vm.purchaseRequest.targetRestockDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.purchaseRequest, "targetRestockDate", $$v)
                  },
                  expression: "purchaseRequest.targetRestockDate",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("PurchaseRequests.Remark") } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 2,
                  disabled:
                    _vm.submitted ||
                    _vm.confirmed ||
                    _vm.rejected ||
                    _vm.completed,
                },
                model: {
                  value: _vm.purchaseRequest.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.purchaseRequest, "remark", $$v)
                  },
                  expression: "purchaseRequest.remark",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.purchaseRequest.purchaseOrders &&
          _vm.purchaseRequest.purchaseOrders.length > 0
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("PurchaseRequests.RelatedPO") } },
                  _vm._l(
                    _vm.purchaseRequest.purchaseOrders,
                    function (purchaseOrder) {
                      return _c(
                        "span",
                        { key: purchaseOrder.uuid },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to:
                                  "/purchase-orders/edit/" + purchaseOrder.uuid,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  purchaseOrder.displayId
                                    ? purchaseOrder.displayId
                                    : purchaseOrder.id
                                )
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("br"),
                        ],
                        1
                      )
                    }
                  ),
                  0
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _vm.createMode
                ? [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", icon: "el-icon-collection" },
                        on: {
                          click: function ($event) {
                            return _vm.onSubmit("Drafted")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.buttonText))]
                    ),
                    _vm._v(" "),
                    _c("el-button", { on: { click: _vm.onCancel } }, [
                      _vm._v(_vm._s(_vm.$t("PurchaseRequests.Back"))),
                    ]),
                  ]
                : _vm.drafted
                ? [
                    _c(
                      "el-row",
                      {
                        staticClass: "row-bg",
                        attrs: { type: "flex", justify: "space-between" },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.submitPurchaseRequest },
                              },
                              [_vm._v(_vm._s(_vm.buttonSubmit))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "danger",
                                  icon: "el-icon-delete",
                                },
                                on: { click: _vm.deletePurchaseRequest },
                              },
                              [_vm._v(_vm._s(_vm.buttonDelete))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "default",
                                  icon: "el-icon-download",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadPurchaseRequest(
                                      _vm.purchaseRequest.uuid,
                                      _vm.purchaseRequest.displayId
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.buttonDownload))]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("el-button", { on: { click: _vm.onCancel } }, [
                              _vm._v(_vm._s(_vm.$t("PurchaseRequests.Back"))),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-collection",
                                },
                                on: { click: _vm.onSubmit },
                              },
                              [_vm._v(_vm._s(_vm.buttonText))]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                : _vm.submitted
                ? [
                    _c(
                      "el-row",
                      {
                        staticClass: "row-bg",
                        attrs: { type: "flex", justify: "space-between" },
                      },
                      [
                        _c(
                          "el-col",
                          { staticClass: "cell", attrs: { span: 18 } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-collection",
                                },
                                on: { click: _vm.onSubmit },
                              },
                              [_vm._v(_vm._s(_vm.buttonText))]
                            ),
                            _vm._v(" "),
                            _vm.canConfirm
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "danger",
                                      icon: "el-icon-circle-close",
                                    },
                                    on: { click: _vm.rejectPurchaseRequest },
                                  },
                                  [_vm._v(_vm._s(_vm.buttonReject))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "success",
                                  plain: "",
                                  icon: "el-icon-download",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadPurchaseRequest(
                                      _vm.purchaseRequest.uuid,
                                      _vm.purchaseRequest.displayId
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.buttonDownload))]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            staticClass: "cell",
                            staticStyle: { "text-align": "right" },
                            attrs: { span: 6 },
                          },
                          [
                            _c("el-button", { on: { click: _vm.onCancel } }, [
                              _vm._v(_vm._s(_vm.$t("PurchaseRequests.Back"))),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm.completed
                ? [
                    _c(
                      "el-row",
                      {
                        staticClass: "row-bg",
                        attrs: { type: "flex", justify: "space-between" },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "default",
                                  icon: "el-icon-download",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadPurchaseRequest(
                                      _vm.purchaseRequest.uuid,
                                      _vm.purchaseRequest.displayId
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.buttonDownload))]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("el-button", { on: { click: _vm.onCancel } }, [
                              _vm._v(_vm._s(_vm.$t("PurchaseRequests.Back"))),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        2
      ),
      _vm._v(" "),
      _vm.purchaseRequest && _vm.dialogFormVisible
        ? [
            _c("add-item-dialog", {
              attrs: {
                "store-id": _vm.purchaseRequest.storeId,
                "dialog-form-visible": _vm.dialogFormVisible,
                "existing-items": _vm.purchaseRequest.items.map(function (
                  item
                ) {
                  return item.rawMaterial
                }),
              },
              on: {
                "update:dialogFormVisible": function ($event) {
                  _vm.dialogFormVisible = $event
                },
                "update:dialog-form-visible": function ($event) {
                  _vm.dialogFormVisible = $event
                },
                complete: _vm.handleAddItemDialogComplete,
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }