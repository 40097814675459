var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        {
          ref: "tabs",
          on: { "tab-click": _vm.handleTabClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _vm.roles.find(function (role) {
            return ["Procurement Manager", "Admin"].includes(role.name)
          })
            ? _c("el-tab-pane", {
                attrs: {
                  label: _vm.$t("PurchaseOrders.Requested"),
                  name: "submitted",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-tab-pane", {
            attrs: {
              label: _vm.$t("PurchaseOrders.Processing"),
              name: "confirmed",
            },
          }),
          _vm._v(" "),
          _c("el-tab-pane", {
            attrs: {
              label: _vm.$t("PurchaseOrders.Outstanding"),
              name: "outstanding",
            },
          }),
          _vm._v(" "),
          _c("el-tab-pane", {
            attrs: {
              label: _vm.$t("PurchaseOrders.Completed"),
              name: "completed",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.activeName === "submitted"
        ? [
            _c(
              "el-row",
              {
                staticClass: "row-bg",
                attrs: { justify: "space-between", gutter: 10 },
              },
              [
                _c(
                  "el-col",
                  { attrs: { span: 24, sm: 14, lg: 17 } },
                  [
                    _c(
                      "el-form",
                      {
                        attrs: {
                          inline: _vm.device !== "mobile",
                          "label-position":
                            _vm.device === "mobile" ? "top" : "right",
                          model: _vm.listQuery,
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: _vm.$t("PurchaseRequests.Stores") },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { clearable: "" },
                                model: {
                                  value: _vm.listQuery.storeId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "storeId", $$v)
                                  },
                                  expression: "listQuery.storeId",
                                },
                              },
                              _vm._l(_vm.stores, function (store) {
                                return _c("el-option", {
                                  key: store.name,
                                  attrs: {
                                    label: store.name,
                                    value: store.uuid,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t(
                                "PurchaseRequests.TargetRestockDate"
                              ),
                            },
                          },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                "value-format": "yyyy-MM-dd",
                                type: "date",
                                clearable: "",
                                placeholder: _vm.$t(
                                  "PurchaseRequests.PickDate"
                                ),
                              },
                              model: {
                                value: _vm.listQuery.targetRestockDate,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.listQuery,
                                    "targetRestockDate",
                                    $$v
                                  )
                                },
                                expression: "listQuery.targetRestockDate",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        !_vm.listLoading
                          ? _c(
                              "el-form-item",
                              { attrs: { label: _vm.$t("route.Supplier") } },
                              [
                                _c("el-autocomplete", {
                                  staticClass: "inline-input",
                                  attrs: {
                                    "fetch-suggestions": _vm.fetchSupplier,
                                    placeholder: "请输入内容",
                                    "value-key": "label",
                                    clearable: "",
                                  },
                                  on: {
                                    clear: _vm.clearSupplier,
                                    select: _vm.handleSelectSupplier,
                                    blur: _vm.checkSupplier,
                                  },
                                  model: {
                                    value: _vm.supplierName,
                                    callback: function ($$v) {
                                      _vm.supplierName = $$v
                                    },
                                    expression: "supplierName",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.query },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("PurchaseRequests.Filter"))
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 24, sm: 10, lg: 7 } },
                  [
                    _c(
                      "el-form",
                      {
                        attrs: { inline: true },
                        nativeOn: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.fetchData($event)
                          },
                        },
                      },
                      [
                        _c(
                          "el-row",
                          {
                            attrs: {
                              type: "flex",
                              justify:
                                _vm.device === "mobile" ? "start" : "end",
                              gutter: 10,
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "padding-left": "5px" } },
                              [
                                _c(
                                  "el-form-item",
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.listQuery.search,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.listQuery, "search", $$v)
                                        },
                                        expression: "listQuery.search",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  [
                                    _vm.activeName === "submitted"
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "default",
                                              icon: "el-icon-search",
                                            },
                                            on: { click: _vm.query },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("Order.Search"))
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "default",
                                              icon: "el-icon-search",
                                            },
                                            on: { click: _vm.fetchData },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("Order.Search"))
                                            ),
                                          ]
                                        ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _c(
            "el-row",
            {
              staticClass: "row-bg",
              attrs: { justify: "space-between", gutter: 10 },
            },
            [
              _c(
                "el-col",
                { attrs: { span: 24, sm: 14, lg: 18 } },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        inline: _vm.device !== "mobile",
                        "label-position":
                          _vm.device === "mobile" ? "top" : "right",
                        model: _vm.listQuery,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "PO " + _vm.$t("Order.Date") + ":" },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "daterange",
                              "range-separator": " - ",
                              "start-placeholder": _vm.$t(
                                "RawMaterialScheduledPrice.StartDate"
                              ),
                              "end-placeholder": _vm.$t(
                                "RawMaterialScheduledPrice.EndDate"
                              ),
                              "value-format": "yyyy-MM-dd",
                            },
                            model: {
                              value: _vm.poDateRange,
                              callback: function ($$v) {
                                _vm.poDateRange = $$v
                              },
                              expression: "poDateRange",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("PurchaseRequests.TargetRestockDate"),
                          },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              "value-format": "yyyy-MM-dd",
                              clearable: "",
                              placeholder: _vm.$t("PurchaseRequests.PickDate"),
                            },
                            model: {
                              value: _vm.listQuery.targetRestockDate,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.listQuery,
                                  "targetRestockDate",
                                  $$v
                                )
                              },
                              expression: "listQuery.targetRestockDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("route.Supplier") } },
                        [
                          _c("el-autocomplete", {
                            staticClass: "inline-input",
                            attrs: {
                              "fetch-suggestions": _vm.fetchSupplier,
                              placeholder: "请输入内容",
                              "value-key": "label",
                              clearable: "",
                            },
                            on: {
                              clear: _vm.clearSupplier,
                              select: _vm.handleSelectSupplier,
                              blur: _vm.checkSupplier,
                            },
                            model: {
                              value: _vm.supplierName,
                              callback: function ($$v) {
                                _vm.supplierName = $$v
                              },
                              expression: "supplierName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.fetchData },
                            },
                            [_vm._v(_vm._s(_vm.$t("PurchaseRequests.Filter")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24, sm: 10, lg: 6 } },
                [
                  _c(
                    "el-form",
                    {
                      attrs: { inline: true },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.fetchData($event)
                        },
                      },
                    },
                    [
                      _c(
                        "el-row",
                        {
                          attrs: {
                            type: "flex",
                            justify: _vm.device === "mobile" ? "start" : "end",
                            gutter: 10,
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticStyle: { "padding-left": "5px" } },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.listQuery.search,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listQuery, "search", $$v)
                                      },
                                      expression: "listQuery.search",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                [
                                  _vm.activeName === "submitted"
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "default",
                                            icon: "el-icon-search",
                                          },
                                          on: { click: _vm.query },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("Order.Search")))]
                                      )
                                    : _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "default",
                                            icon: "el-icon-search",
                                          },
                                          on: { click: _vm.fetchData },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("Order.Search")))]
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _vm._v(" "),
      _c(
        "el-row",
        {
          staticClass: "row-bg",
          staticStyle: { "margin-top": "20px" },
          attrs: { justify: "space-between", gutter: 10 },
        },
        [
          _c(
            "el-col",
            [
              _c(
                "el-form",
                { attrs: { inline: true } },
                [
                  _vm.activeName === "submitted"
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: {
                            type: "warning",
                            icon: "el-icon-copy-document",
                          },
                          on: { click: _vm.previewData },
                        },
                        [_vm._v("Preview Purchase")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _vm.activeName === "submitted"
            ? [
                _c(
                  "el-table-column",
                  {
                    attrs: { align: "center", width: "50" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              -1 !==
                                ["Submitted", "Confirmed"].indexOf(
                                  scope.row.status
                                ) &&
                              _vm.filterStatus(scope.row.items, "pending")
                                .length > 0
                                ? _c("el-checkbox", {
                                    model: {
                                      value: scope.row.isEnabled,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "isEnabled", $$v)
                                      },
                                      expression: "scope.row.isEnabled",
                                    },
                                  })
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2842665113
                    ),
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("el-checkbox", {
                          on: { change: _vm.toggleAllEnable },
                        }),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "ID", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.displayId) + "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _vm.activeName === "submitted"
            ? [
                _c("el-table-column", {
                  attrs: { label: _vm.$t("PurchaseRequests.Store") },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(scope.row.store.name) +
                                "\n        "
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3968359019
                  ),
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.activeName === "submitted"
            ? [
                _c("el-table-column", {
                  attrs: { label: _vm.$t("route.Supplier") },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.getSupplierName(scope.row.items)) +
                                "\n        "
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2722925695
                  ),
                }),
              ]
            : [
                _c("el-table-column", {
                  attrs: { label: _vm.$t("route.Supplier") },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                scope.row.supplier
                                  ? scope.row.supplier.name
                                  : "DELETED"
                              ) +
                              "\n        "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
          _vm._v(" "),
          _vm.activeName !== "submitted"
            ? [
                _c("el-table-column", {
                  attrs: { label: _vm.$t("PurchaseRequests.Store") },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(scope.row.stores) +
                                "\n        "
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3328569745
                  ),
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Target Restock", width: "130" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.targetRestockDate) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _vm.activeName !== "submitted"
            ? [
                _c("el-table-column", {
                  attrs: { label: "PO " + _vm.$t("Order.Date"), width: "130" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(scope.row.poDate) +
                                "\n        "
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    167890838
                  ),
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Order.Status"), width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("PurchaseOrders." + scope.row.status)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Order.UpdatedAt") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.updatedAt) + "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Order.Actions"), "class-name": "action" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      [
                        scope.row.status == "Drafted" ||
                        scope.row.status == "Confirmed" ||
                        scope.row.status == "Outstanding"
                          ? _c(
                              "router-link",
                              {
                                staticClass: "el-button",
                                class: {
                                  "el-button--warning":
                                    scope.row.status === "Drafted",
                                  "el-button--primary":
                                    scope.row.status !== "Drafted",
                                },
                                attrs: {
                                  to: "/purchase-orders/edit/" + scope.row.uuid,
                                },
                              },
                              [
                                _c("i", {
                                  class:
                                    scope.row.status === "Drafted"
                                      ? "el-icon-edit"
                                      : "el-icon-document",
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.status === "Drafted"
                                        ? _vm.$t("Order.Edit")
                                        : _vm.$t("Order.Show")
                                    )
                                ),
                              ]
                            )
                          : _vm.activeName === "submitted"
                          ? _c(
                              "router-link",
                              {
                                staticClass: "el-button el-button--primary",
                                attrs: {
                                  to:
                                    "/purchase-requests/edit/" + scope.row.uuid,
                                },
                              },
                              [
                                _c("i", { staticClass: "el-icon-document" }),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("PurchaseRequests.Show"))
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == "Confirmed" ||
                        scope.row.status == "Outstanding" ||
                        scope.row.status == "Completed"
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "success",
                                  plain: "",
                                  disabled: _vm.isOffline,
                                  icon: "el-icon-download",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadPurchaseOrder(
                                      scope.row.uuid,
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("PurchaseRequests.Download"))
                                ),
                              ]
                            )
                          : _vm.activeName === "submitted" &&
                            (scope.row === "drafted" ||
                              _vm.roles.find(function (role) {
                                return ["Operation Manager", "Admin"].includes(
                                  role.name
                                )
                              }))
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "danger",
                                  disabled: _vm.isOffline,
                                  icon: "el-icon-circle-close",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.rejectPurchaseRequest(
                                      scope.row.uuid
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("PurchaseRequests.Reject"))
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.activeName !== "submitted" &&
                        (_vm.activeName === "drafted" ||
                          (_vm.activeName === "confirmed" &&
                            (!scope.row.goodReceivedNotes ||
                              scope.row.goodReceivedNotes.length == 0) &&
                            _vm.roles.find(function (role) {
                              return ["Operation Manager", "Admin"].includes(
                                role.name
                              )
                            })))
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "danger",
                                  disabled: _vm.isOffline,
                                  icon: "el-icon-delete",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deletePurchaseOrder(
                                      scope.row.uuid
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("Order.Delete")))]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    ["Completed", "Confirmed", "Outstanding"].indexOf(
                      scope.row.status
                    ) !== -1 &&
                    scope.row.goodReceivedNotes &&
                    scope.row.goodReceivedNotes.length
                      ? [
                          _c(
                            "div",
                            [
                              _c(
                                "el-dropdown",
                                { attrs: { trigger: "click" } },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "el-dropdown-link" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type:
                                              scope.row.goodReceivedNotes.find(
                                                function (goodReceivedNote) {
                                                  return ["Confirmed"].includes(
                                                    goodReceivedNote.status
                                                  )
                                                }
                                              ) !== undefined
                                                ? "warning"
                                                : "success",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              scope.row.goodReceivedNotes.find(
                                                function (goodReceivedNote) {
                                                  return ["Confirmed"].includes(
                                                    goodReceivedNote.status
                                                  )
                                                }
                                              ) !== undefined
                                                ? "Waiting Allocation"
                                                : scope.row.goodReceivedNotes.find(
                                                    function (
                                                      goodReceivedNote
                                                    ) {
                                                      return [
                                                        "Completed",
                                                      ].includes(
                                                        goodReceivedNote.status
                                                      )
                                                    }
                                                  ) !== undefined
                                                ? _vm.$t(
                                                    "PurchaseRequests.Show"
                                                  ) +
                                                  " " +
                                                  _vm.$t("route.GoodReceived")
                                                : "Good Received"
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      staticClass: "allocation-dropdown",
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown",
                                    },
                                    _vm._l(
                                      scope.row.goodReceivedNotes.filter(
                                        function (goodReceivedNote) {
                                          return (
                                            goodReceivedNote.type === "received"
                                          )
                                        }
                                      ),
                                      function (goodReceivedNote, i) {
                                        return _c(
                                          "el-dropdown-item",
                                          {
                                            key: "GoodReceivedNotes" + i,
                                            attrs: {
                                              command:
                                                "" + goodReceivedNote.uuid,
                                            },
                                          },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                attrs: {
                                                  to:
                                                    "/good-received-notes/edit/" +
                                                    goodReceivedNote.uuid,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    goodReceivedNote.displayId
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.activeName === "submitted"
        ? [
            _c("pagination", {
              attrs: {
                total: _vm.total,
                page: _vm.listQuery.page,
                limit: _vm.listQuery.limit,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.listQuery, "page", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.listQuery, "limit", $event)
                },
                pagination: _vm.fetchPR,
              },
            }),
          ]
        : [
            _c("pagination", {
              attrs: {
                total: _vm.total,
                page: _vm.listQuery.page,
                limit: _vm.listQuery.perPage,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.listQuery, "page", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.listQuery, "perPage", $event)
                },
                pagination: _vm.fetchData,
              },
            }),
          ],
      _vm._v(" "),
      _vm.summary && _vm.summaryVisible
        ? [
            _c(
              "el-dialog",
              {
                attrs: { title: "Summary", visible: _vm.summaryVisible },
                on: {
                  "update:visible": function ($event) {
                    _vm.summaryVisible = $event
                  },
                },
              },
              [
                _c("multiselect", {
                  attrs: {
                    options: _vm.options,
                    multiple: true,
                    "custom-label": function (option) {
                      return option.name
                    },
                    "show-labels": false,
                    label: "uuid",
                    "track-by": "uuid",
                  },
                  model: {
                    value: _vm.supplierFilter,
                    callback: function ($$v) {
                      _vm.supplierFilter = $$v
                    },
                    expression: "supplierFilter",
                  },
                }),
                _vm._v(" "),
                _vm._l(_vm.summary, function (supplier, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "tag",
                            fn: function (ref) {
                              var option = ref.option
                              var remove = ref.remove
                              return [
                                _c(
                                  "span",
                                  { staticClass: "multiselect__tag" },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(option.shortName)),
                                    ]),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass: "multiselect__tag-icon",
                                      attrs: {
                                        "aria-hidden": "true",
                                        tabindex: "1",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return remove(option)
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _vm._v(" "),
                      _vm.supplierFilter.length == 0 ||
                      _vm.filterSupplier(_vm.supplierFilter, supplier.uuid)
                        .length > 0
                        ? _c(
                            "div",
                            [
                              _c("h3", [
                                _vm._v(
                                  _vm._s(supplier.name) +
                                    " (" +
                                    _vm._s(_vm.$t("Order.Total")) +
                                    ": $" +
                                    _vm._s(supplier.total.toFixed(2)) +
                                    ")"
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-table",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { data: supplier.items },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      label: _vm.$t("PurchaseRequests.Item"),
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(scope.row.sku) +
                                                  "\n              "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: _vm.$t("PurchaseRequests.Qty"),
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(scope.row.qty) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.pluralize(
                                                      scope.row.unit,
                                                      scope.row.qty
                                                    )
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: _vm.$t(
                                        "PurchaseRequests.UnitPrice"
                                      ),
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                $" +
                                                  _vm._s(
                                                    scope.row.unitPrice
                                                      ? scope.row.unitPrice.toFixed(
                                                          2
                                                        )
                                                      : "null"
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: _vm.$t(
                                        "PurchaseRequests.SubTotal"
                                      ),
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                $" +
                                                  _vm._s(
                                                    (
                                                      (scope.row.unitPrice
                                                        ? scope.row.unitPrice.toFixed(
                                                            2
                                                          )
                                                        : 0) * scope.row.qty
                                                    ).toFixed(2)
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.summaryVisible = false
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("PurchaseRequests.Cancel")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.generatePO },
                      },
                      [_vm._v(_vm._s(_vm.$t("PurchaseRequests.GeneratePO")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.generatePOByStore },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("PurchaseRequests.GeneratePOByStore"))
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }