"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _supplier = require("@/api/supplier");

var _rawMaterial = require("@/api/rawMaterial");

var _category = require("@/api/category");

var _vueSelect = _interopRequireDefault(require("vue-select"));

require("vue-select/src/scss/vue-select.scss");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import i18n from '@/i18n'
var _default = {
  name: 'AddItemDialog',
  components: {
    vSelect: _vueSelect.default,
    Pagination: _Pagination.default
  },
  props: {
    supplierId: {
      type: String,
      required: true
    },
    dialogFormVisible: {
      type: Boolean,
      default: false
    },
    existingItems: {
      // rawMaterials
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      suppliers: [],
      listQuery: {
        page: 1,
        limit: 10,
        listAll: true,
        supplierId: null,
        categoryId: null,
        keyword: null
      },
      categories: [],
      rawMaterials: null,
      selectedRawMaterials: []
    };
  },

  computed: {
    visible: {
      get() {
        return this.dialogFormVisible;
      },

      set(val) {
        this.$emit('update:dialogFormVisible', val);
      }

    }
  },
  watch: {
    supplier() {
      this.listQuery.supplierId = this.supplierId;
    }

  },

  async created() {
    // this.role = this.newDuty.roleId
    // this.fetchData()
    this.listQuery.supplierId = this.supplierId;
    (0, _supplier.getSupplier)(this.supplierId).then(supplierResponse => {
      this.suppliers = [supplierResponse.data];
    });
    (0, _category.getCategories)({
      perPage: 999
    }).then(categoryResponse => {
      this.categories = categoryResponse.data;
    });
    this.fetchData();
  },

  methods: {
    async fetchData() {
      (0, _rawMaterial.getRawMaterials)({ ...this.listQuery,
        storeId: ''
      }).then(response => {
        this.rawMaterials = response.data.filter(rawMaterial => this.existingItems.findIndex(item => item.uuid === rawMaterial.uuid));
        this.total = response.meta.total;
      });
    },

    handleSelect(rawMaterial) {
      let found = false;
      this.selectedRawMaterials.forEach(selectedRawMaterial => {
        if (selectedRawMaterial.uuid === rawMaterial.uuid) {
          found = true;
        }
      });

      if (!found) {
        this.selectedRawMaterials.push(rawMaterial);
      } // this.$emit('complete', rawMaterial)
      // this.$emit('update:dialogFormVisible', false)

    },

    handleIsSelected(rawMaterial) {
      return !!this.selectedRawMaterials.find(selectedRawMaterial => selectedRawMaterial.uuid === rawMaterial.uuid);
    },

    handleDeselect(rawMaterial) {
      this.selectedRawMaterials = this.selectedRawMaterials.filter(selectedRawMaterial => selectedRawMaterial.uuid !== rawMaterial.uuid);
    },

    handleSubmit() {
      this.$emit('complete', this.selectedRawMaterials);
      this.$emit('update:dialogFormVisible', false);
    },

    handleCancel() {
      this.$emit('update:dialogFormVisible', false);
    }

  }
};
exports.default = _default;