var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _vm.purchaseOrder && _vm.dialogFormVisible
        ? _c("add-item-dialog", {
            attrs: {
              "dialog-form-visible": _vm.dialogFormVisible,
              "supplier-id": _vm.purchaseOrder.supplierId,
              "existing-items": _vm.purchaseOrder.items.map(function (item) {
                return item.rawMaterial
              }),
            },
            on: {
              "update:dialogFormVisible": function ($event) {
                _vm.dialogFormVisible = $event
              },
              "update:dialog-form-visible": function ($event) {
                _vm.dialogFormVisible = $event
              },
              complete: _vm.handleAddItemDialogComplete,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.purchaseOrder && _vm.dialogCopyFormVisible
        ? _c("copy-item-dialog", {
            attrs: {
              "dialog-form-visible": _vm.dialogCopyFormVisible,
              "display-id": _vm.purchaseOrder.displayId,
              items: _vm.purchaseOrder.items,
              "supplier-id": _vm.purchaseOrder.supplierId,
            },
            on: {
              "update:dialogFormVisible": function ($event) {
                _vm.dialogCopyFormVisible = $event
              },
              "update:dialog-form-visible": function ($event) {
                _vm.dialogCopyFormVisible = $event
              },
              complete: _vm.handleCopyItemDialogComplete,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.purchaseOrder,
            rules: _vm.rules,
            "label-width": "auto",
            "label-position": _vm.labelPosition,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "ID", prop: "displayId" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.purchaseOrder.displayId,
                  callback: function ($$v) {
                    _vm.$set(_vm.purchaseOrder, "displayId", $$v)
                  },
                  expression: "purchaseOrder.displayId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: { label: _vm.$t("PurchaseOrders.Status"), prop: "status" },
            },
            [
              _c("el-input", {
                attrs: {
                  value:
                    _vm.purchaseOrder.status &&
                    _vm.purchaseOrder.status !== "Drafted"
                      ? _vm.$t("PurchaseOrders." + _vm.purchaseOrder.status)
                      : "",
                  disabled: "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("route.Supplier"), prop: "supplierId" } },
            [
              _c("v-select", {
                attrs: {
                  disabled: _vm.confirmed || _vm.outstanding || _vm.completed,
                  options: _vm.suppliers,
                  reduce: function (supplier) {
                    return supplier.uuid
                  },
                  label: "name",
                },
                model: {
                  value: _vm.purchaseOrder.supplierId,
                  callback: function ($$v) {
                    _vm.$set(_vm.purchaseOrder, "supplierId", $$v)
                  },
                  expression: "purchaseOrder.supplierId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: { label: _vm.$t("PurchaseOrders.PODate"), prop: "poDate" },
            },
            [
              _c("el-date-picker", {
                attrs: {
                  disabled: _vm.confirmed || _vm.outstanding || _vm.completed,
                  placeholder: _vm.$t("PurchaseRequests.PickDate"),
                  "value-format": "yyyy-MM-dd",
                  type: "date",
                },
                model: {
                  value: _vm.purchaseOrder.poDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.purchaseOrder, "poDate", $$v)
                  },
                  expression: "purchaseOrder.poDate",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("PurchaseOrders.DelivDate"),
                prop: "targetRestockDate",
              },
            },
            [
              _c("el-date-picker", {
                attrs: {
                  disabled: _vm.confirmed || _vm.outstanding || _vm.completed,
                  placeholder: _vm.$t("PurchaseRequests.PickDate"),
                  "value-format": "yyyy-MM-dd",
                  type: "date",
                },
                model: {
                  value: _vm.purchaseOrder.targetRestockDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.purchaseOrder, "targetRestockDate", $$v)
                  },
                  expression: "purchaseOrder.targetRestockDate",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("PurchaseOrders.ContactName"),
                prop: "contactName",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.confirmed || _vm.outstanding || _vm.completed,
                  maxlength: "30",
                },
                model: {
                  value: _vm.purchaseOrder.contactName,
                  callback: function ($$v) {
                    _vm.$set(_vm.purchaseOrder, "contactName", $$v)
                  },
                  expression: "purchaseOrder.contactName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("PurchaseOrders.ContactTel"),
                prop: "contactTel",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.confirmed || _vm.outstanding || _vm.completed,
                },
                model: {
                  value: _vm.purchaseOrder.contactTel,
                  callback: function ($$v) {
                    _vm.$set(_vm.purchaseOrder, "contactTel", $$v)
                  },
                  expression: "purchaseOrder.contactTel",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("PurchaseOrders.ContactEmail"),
                rules: [
                  {
                    type: "email",
                    message: "Please input correct email address",
                    trigger: ["blur", "change"],
                  },
                ],
                prop: "contactEmail",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.confirmed || _vm.outstanding || _vm.completed,
                  type: "email",
                },
                model: {
                  value: _vm.purchaseOrder.contactEmail,
                  callback: function ($$v) {
                    _vm.$set(_vm.purchaseOrder, "contactEmail", $$v)
                  },
                  expression: "purchaseOrder.contactEmail",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("PurchaseOrders.SuppContactName"),
                prop: "supplierContactName",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.confirmed || _vm.outstanding || _vm.completed,
                  maxlength: "30",
                },
                model: {
                  value: _vm.purchaseOrder.supplierContactName,
                  callback: function ($$v) {
                    _vm.$set(_vm.purchaseOrder, "supplierContactName", $$v)
                  },
                  expression: "purchaseOrder.supplierContactName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("PurchaseOrders.SuppContactTel"),
                prop: "supplierContactTel",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.confirmed || _vm.outstanding || _vm.completed,
                },
                model: {
                  value: _vm.purchaseOrder.supplierContactTel,
                  callback: function ($$v) {
                    _vm.$set(_vm.purchaseOrder, "supplierContactTel", $$v)
                  },
                  expression: "purchaseOrder.supplierContactTel",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("PurchaseOrders.SuppContactEmail"),
                rules: [
                  {
                    type: "email",
                    message: "Please input correct email address",
                    trigger: ["blur", "change"],
                  },
                ],
                prop: "supplierContactEmail",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.confirmed || _vm.outstanding || _vm.completed,
                },
                model: {
                  value: _vm.purchaseOrder.supplierContactEmail,
                  callback: function ($$v) {
                    _vm.$set(_vm.purchaseOrder, "supplierContactEmail", $$v)
                  },
                  expression: "purchaseOrder.supplierContactEmail",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.purchaseOrder.supplierId
            ? _c(
                "el-form-item",
                {
                  key: _vm.purchaseOrder.supplierId,
                  attrs: {
                    prop: "items",
                    label: _vm.$t("PurchaseOrders.PurchaseRequestItem"),
                  },
                },
                [
                  _vm.drafted || _vm.confirmed
                    ? _c(
                        "div",
                        { staticStyle: { "margin-top": "20px" } },
                        [
                          _c("el-button", { on: { click: _vm.addRow } }, [
                            _vm._v(_vm._s(_vm.$t("PurchaseOrders.AddItem"))),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.purchaseOrder.items },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "expand" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-table",
                                    {
                                      attrs: {
                                        data:
                                          _vm.drafted || _vm.confirmed
                                            ? scope.row.allocations
                                            : scope.row.allocations
                                                .filter(function (alloaction) {
                                                  return _vm.purchaseOrder.purchaseRequests
                                                    .map(function (
                                                      purchaseRequest
                                                    ) {
                                                      return purchaseRequest
                                                        .store.uuid
                                                    })
                                                    .includes(
                                                      alloaction.store.uuid
                                                    )
                                                })
                                                .sort(function (a, b) {
                                                  return a.qty < b.qty
                                                    ? 1
                                                    : a.qty > b.qty
                                                    ? -1
                                                    : 0
                                                }),
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          label: _vm.$t(
                                            "PurchaseRequests.Store"
                                          ),
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (warehouse) {
                                                return [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        warehouse.row.store.name
                                                      )
                                                  ),
                                                  _c("br"),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: _vm.$t(
                                            "PurchaseOrders.ReqQty"
                                          ),
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (allocation) {
                                                return [
                                                  _c("el-input-number", {
                                                    attrs: {
                                                      disabled:
                                                        _vm.completed ||
                                                        _vm.outstanding,
                                                      min: 0,
                                                      precision: 2,
                                                      size: "small",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.recalculate(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: allocation.row.qty,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          allocation.row,
                                                          "qty",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "allocation.row.qty",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("br"),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2292841264
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: _vm.$t("PurchaseOrders.Name") },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(scope.row.rawMaterial.sku)
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        scope.row.rawMaterial.nameEng
                                          ? scope.row.rawMaterial.nameEng
                                          : scope.row.rawMaterial.nameChin
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          675265711
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("PurchaseOrders.Qty"),
                          width: "180",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("GoodReceivedNotes.Qty")) +
                                      ":\n            "
                                  ),
                                  _c("el-input-number", {
                                    attrs: {
                                      disabled:
                                        _vm.outstanding ||
                                        _vm.completed ||
                                        _vm.createMode,
                                      min: 0,
                                      precision: 2,
                                      size: "small",
                                    },
                                    model: {
                                      value: scope.row.qty,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "qty", $$v)
                                      },
                                      expression: "scope.row.qty",
                                    },
                                  }),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.pluralize(
                                          scope.row.rawMaterial.unit,
                                          scope.row.qty
                                        )
                                      )
                                  ),
                                  _c("br"),
                                  _vm._v(" "),
                                  _vm.outstanding || _vm.completed
                                    ? _c("div", [
                                        _vm._v(
                                          "\n              Received:\n              " +
                                            _vm._s(scope.row.receivedQty) +
                                            "\n              " +
                                            _vm._s(
                                              _vm.pluralize(
                                                scope.row.rawMaterial.unit,
                                                scope.row.receivedQty
                                              )
                                            )
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.canceledQty &&
                                  (_vm.outstanding || _vm.completed)
                                    ? _c("div", [
                                        _vm._v(
                                          "\n              Canceled:\n              " +
                                            _vm._s(scope.row.canceledQty) +
                                            "\n              " +
                                            _vm._s(
                                              _vm.pluralize(
                                                scope.row.rawMaterial.unit,
                                                scope.row.canceledQty
                                              )
                                            )
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.outstanding || _vm.completed
                                    ? _c(
                                        "div",
                                        {
                                          class: {
                                            "color-danger":
                                              scope.row.qty -
                                                scope.row.receivedQty >
                                              0,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              Outstanding:\n              " +
                                              _vm._s(
                                                parseFloat(
                                                  scope.row.qty -
                                                    (scope.row.receivedQty +
                                                      scope.row.canceledQty)
                                                ).toFixed(2)
                                              ) +
                                              "\n              " +
                                              _vm._s(
                                                _vm.pluralize(
                                                  scope.row.rawMaterial.unit,
                                                  scope.row.qty -
                                                    (scope.row.receivedQty +
                                                      scope.row.canceledQty)
                                                )
                                              )
                                          ),
                                        ]
                                      )
                                    : _vm.createMode
                                    ? _c(
                                        "div",
                                        {
                                          class: {
                                            "color-danger":
                                              scope.row.qty -
                                                _vm.requestedTotal(scope.row) !=
                                              0,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "Outstanding: " +
                                              _vm._s(
                                                parseFloat(
                                                  scope.row.qty -
                                                    _vm.requestedTotal(
                                                      scope.row
                                                    )
                                                ).toFixed(2)
                                              )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          613241022
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("PurchaseOrders.UnitPrice"),
                          width: "150",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input-number", {
                                    attrs: {
                                      disabled:
                                        _vm.confirmed ||
                                        _vm.outstanding ||
                                        _vm.completed,
                                      min: 0,
                                      precision: 2,
                                      size: "small",
                                    },
                                    model: {
                                      value: scope.row.unitPrice,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "unitPrice", $$v)
                                      },
                                      expression: "scope.row.unitPrice",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    "\n            (Current: $" +
                                      _vm._s(
                                        scope.row.rawMaterial.defaultPrice
                                          ? scope.row.rawMaterial.defaultPrice.toFixed(
                                              2
                                            )
                                          : 0
                                      ) +
                                      ")\n          "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3479746695
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: _vm.$t("PurchaseOrders.Subtotal") },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n            $" +
                                      _vm._s(
                                        (scope.row.unitPrice * scope.row.qty)
                                          .toFixed(2)
                                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          756913036
                        ),
                      }),
                      _vm._v(" "),
                      _vm.confirmed || _vm.drafted
                        ? _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("PurchaseOrders.Remark"),
                              width: "180",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input", {
                                        attrs: {
                                          disabled:
                                            _vm.confirmed ||
                                            _vm.outstanding ||
                                            _vm.completed,
                                          type: "textarea",
                                        },
                                        model: {
                                          value: scope.row.remark,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "remark", $$v)
                                          },
                                          expression: "scope.row.remark",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1626871497
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !(_vm.outstanding || _vm.completed) &&
                      _vm.purchaseOrder.items.length > 1
                        ? _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("PurchaseOrders.Operations"),
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "danger",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDelete(
                                                scope.$index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Delete\n            ")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1025271471
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("Order.Total")) +
                      ": $" +
                      _vm._s(_vm.total.toFixed(2)) +
                      "\n    "
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("PurchaseOrders.DelivAddress"),
                prop: "deliveryAddress",
              },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.purchaseOrder.deliveryAddress,
                  callback: function ($$v) {
                    _vm.$set(_vm.purchaseOrder, "deliveryAddress", $$v)
                  },
                  expression: "purchaseOrder.deliveryAddress",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("PurchaseOrders.Remark") } },
            [
              _c("el-input", {
                attrs: { disabled: _vm.completed, rows: 2, type: "textarea" },
                model: {
                  value: _vm.purchaseOrder.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.purchaseOrder, "remark", $$v)
                  },
                  expression: "purchaseOrder.remark",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("PurchaseOrders.ReceiveRemark") } },
            [
              _c("el-input", {
                attrs: {
                  disabled: !(_vm.confirmed || _vm.outstanding),
                  rows: 2,
                  type: "textarea",
                },
                model: {
                  value: _vm.purchaseOrder.receiveRemark,
                  callback: function ($$v) {
                    _vm.$set(_vm.purchaseOrder, "receiveRemark", $$v)
                  },
                  expression: "purchaseOrder.receiveRemark",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.purchaseOrder.purchaseRequests &&
          _vm.purchaseOrder.purchaseRequests.length > 0
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("PurchaseOrders.RelatedPR") } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        type: "primary",
                        plain: "",
                        disabled: _vm.isOffline,
                        icon: "el-icon-download",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.downloadRelatedPR(
                            _vm.purchaseOrder.uuid,
                            _vm.purchaseOrder.displayId
                          )
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("PurchaseRequests.Download")) +
                          " PR\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _vm._l(
                    _vm.purchaseOrder.purchaseRequests,
                    function (purchaseRequest) {
                      return _c(
                        "span",
                        { key: purchaseRequest.uuid },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to:
                                  "/purchase-requests/edit/" +
                                  purchaseRequest.uuid,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  purchaseRequest.displayId
                                    ? purchaseRequest.displayId
                                    : purchaseRequest.uuid
                                )
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("br"),
                        ],
                        1
                      )
                    }
                  ),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.purchaseOrder.goodReceivedNotes &&
          _vm.purchaseOrder.goodReceivedNotes.length > 0
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("PurchaseOrders.RelatedGR") } },
                _vm._l(
                  _vm.purchaseOrder.goodReceivedNotes,
                  function (goodReceivedNote) {
                    return _c(
                      "span",
                      { key: goodReceivedNote.uuid },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to:
                                "/good-received-notes/edit/" +
                                goodReceivedNote.uuid,
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                goodReceivedNote.displayId
                                  ? goodReceivedNote.displayId
                                  : goodReceivedNote.uuid
                              )
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("br"),
                      ],
                      1
                    )
                  }
                ),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _vm.createMode
                ? [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "success",
                          disabled: _vm.isOffline,
                          icon: "el-icon-collection",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onSubmit("Drafted")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.buttonText))]
                    ),
                    _vm._v(" "),
                    _c("el-button", { on: { click: _vm.onCancel } }, [
                      _vm._v(_vm._s(_vm.$t("PurchaseOrders.BackToList"))),
                    ]),
                  ]
                : _vm.drafted
                ? [
                    _c(
                      "el-row",
                      {
                        staticClass: "row-bg",
                        attrs: { justify: "space-between", type: "flex" },
                      },
                      [
                        _c(
                          "el-col",
                          { staticClass: "cell", attrs: { span: 16 } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  disabled: _vm.isOffline,
                                  icon: "el-icon-circle-check",
                                },
                                on: { click: _vm.confirmPurchaseOrder },
                              },
                              [_vm._v(_vm._s(_vm.buttonConfirm))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "danger",
                                  disabled: _vm.isOffline,
                                  icon: "el-icon-delete",
                                },
                                on: { click: _vm.deletePurchaseOrder },
                              },
                              [_vm._v(_vm._s(_vm.buttonDelete))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "success",
                                  plain: "",
                                  disabled: _vm.isOffline,
                                  icon: "el-icon-download",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadPurchaseOrder(
                                      _vm.purchaseOrder.uuid,
                                      _vm.purchaseOrder.displayId
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t("PurchaseOrders.DownloadPO")
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticStyle: { display: "none" },
                                attrs: { icon: "el-icon-document-copy" },
                                on: { click: _vm.generateText },
                              },
                              [_vm._v("Copy")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            staticClass: "cell",
                            staticStyle: { "text-align": "right" },
                            attrs: { span: 8 },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "success",
                                  disabled: _vm.isOffline,
                                  icon: "el-icon-collection",
                                },
                                on: { click: _vm.onSubmit },
                              },
                              [_vm._v(_vm._s(_vm.buttonText))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-top": "3px" },
                                on: { click: _vm.onCancel },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("PurchaseOrders.BackToList"))
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm.confirmed
                ? [
                    _c(
                      "el-row",
                      {
                        staticClass: "row-bg",
                        attrs: { justify: "space-between", type: "flex" },
                      },
                      [
                        _c(
                          "el-col",
                          { staticClass: "cell", attrs: { span: 16 } },
                          [
                            _vm.purchaseOrder.status === "Confirmed" ||
                            _vm.purchaseOrder.status === "Outstanding"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      disabled: _vm.isOffline,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.generateGRFromPurchaseOrder(
                                          _vm.purchaseOrder.uuid,
                                          _vm.purchaseOrder.displayId
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.buttonGenerateGR) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-top": "3px" },
                                attrs: {
                                  type: "success",
                                  plain: "",
                                  disabled: _vm.isOffline,
                                  icon: "el-icon-download",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadPurchaseOrder(
                                      _vm.purchaseOrder.uuid,
                                      _vm.purchaseOrder.displayId
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t("PurchaseOrders.DownloadPO")
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.purchaseOrder.status === "Confirmed" &&
                            (!_vm.purchaseOrder.goodReceivedNotes ||
                              _vm.purchaseOrder.goodReceivedNotes.length == 0)
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "danger",
                                      disabled: _vm.isOffline,
                                      icon: "el-icon-delete",
                                    },
                                    on: { click: _vm.deletePurchaseOrder },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.buttonDelete) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  display: "none",
                                  "margin-top": "3px",
                                },
                                attrs: { icon: "el-icon-circle" },
                                on: { click: _vm.generateText },
                              },
                              [_vm._v("Copy")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            staticClass: "cell",
                            staticStyle: { "text-align": "right" },
                            attrs: { span: 8 },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "success",
                                  disabled: _vm.isOffline,
                                  icon: "el-icon-collection",
                                },
                                on: { click: _vm.onSubmit },
                              },
                              [_vm._v(_vm._s(_vm.buttonText))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-top": "3px" },
                                on: { click: _vm.onCancel },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("PurchaseOrders.BackToList"))
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm.outstanding
                ? [
                    _c(
                      "el-row",
                      {
                        staticClass: "row-bg",
                        attrs: { justify: "space-between", type: "flex" },
                      },
                      [
                        _c(
                          "el-col",
                          { staticClass: "cell", attrs: { span: 16 } },
                          [
                            _vm.purchaseOrder.status === "Confirmed" ||
                            _vm.purchaseOrder.status === "Outstanding"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      disabled: _vm.isOffline,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.generateGRFromPurchaseOrder(
                                          _vm.purchaseOrder.uuid,
                                          _vm.purchaseOrder.displayId
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.buttonGenerateGR) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-top": "3px" },
                                attrs: {
                                  type: "success",
                                  plain: "",
                                  disabled: _vm.isOffline,
                                  icon: "el-icon-download",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadPurchaseOrder(
                                      _vm.purchaseOrder.uuid,
                                      _vm.purchaseOrder.displayId
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t("PurchaseOrders.DownloadPO")
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            staticClass: "cell",
                            staticStyle: { "text-align": "right" },
                            attrs: { span: 8 },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "success",
                                  disabled: _vm.isOffline,
                                  icon: "el-icon-collection",
                                },
                                on: { click: _vm.onSubmit },
                              },
                              [_vm._v(_vm._s(_vm.buttonText))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-top": "3px" },
                                on: { click: _vm.onCancel },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("PurchaseOrders.BackToList"))
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm.completed
                ? [
                    _c(
                      "el-row",
                      {
                        staticClass: "row-bg",
                        attrs: { justify: "space-between", type: "flex" },
                      },
                      [
                        _c(
                          "el-col",
                          { staticClass: "cell", attrs: { span: 16 } },
                          [
                            _vm.purchaseOrder.goodReceivedNotes &&
                            _vm.purchaseOrder.goodReceivedNotes[0]
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass: "el-button el-button--warning",
                                    attrs: {
                                      to:
                                        "/good-received-notes/edit/" +
                                        _vm.purchaseOrder.goodReceivedNotes[
                                          _vm.purchaseOrder.goodReceivedNotes
                                            .length - 1
                                        ].uuid,
                                    },
                                  },
                                  [_vm._v("Good Received")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-top": "3px" },
                                attrs: {
                                  type: "success",
                                  plain: "",
                                  disabled: _vm.isOffline,
                                  icon: "el-icon-download",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadPurchaseOrder(
                                      _vm.purchaseOrder.uuid,
                                      _vm.purchaseOrder.displayId
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t("PurchaseOrders.DownloadPO")
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            staticClass: "cell",
                            staticStyle: { "text-align": "right" },
                            attrs: { span: 8 },
                          },
                          [
                            _c("el-button", { on: { click: _vm.onCancel } }, [
                              _vm._v(
                                _vm._s(_vm.$t("PurchaseOrders.BackToList"))
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }