var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.message,
            "label-width": "auto",
            "label-position": _vm.device === "mobile" ? "top" : "left",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "ID" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.message.uuid,
                  callback: function ($$v) {
                    _vm.$set(_vm.message, "uuid", $$v)
                  },
                  expression: "message.uuid",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Title" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.message.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.message, "title", $$v)
                  },
                  expression: "message.title",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Content" } },
            [
              _c("el-input", {
                attrs: { type: "textarea" },
                model: {
                  value: _vm.message.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.message, "content", $$v)
                  },
                  expression: "message.content",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("Order.User") } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: _vm.$t("route.Users"),
                    multiple: "",
                    clearable: "",
                    filterable: "",
                    "filter-method": _vm.userFilter,
                  },
                  model: {
                    value: _vm.message.userIds,
                    callback: function ($$v) {
                      _vm.$set(_vm.message, "userIds", $$v)
                    },
                    expression: "message.userIds",
                  },
                },
                _vm._l(_vm.userOptions, function (user) {
                  return _c("el-option", {
                    key: user.name,
                    attrs: { label: user.name, value: user.uuid },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Attachment", prop: "attachment" } },
            [
              _c(
                "el-upload",
                {
                  staticClass: "upload-demo",
                  attrs: {
                    name: "attachment[]",
                    accept: ".png,.jpg,.jpeg",
                    action:
                      _vm.uploadDomain + "/api/v1/message-attachment/upload",
                    "on-preview": _vm.handlePreview,
                    "on-remove": _vm.handleRemove,
                    "before-remove": _vm.beforeRemove,
                    "on-success": _vm.handleSuccess,
                    multiple: "",
                    drag: "",
                    limit: 3,
                    "on-exceed": _vm.handleExceed,
                    "file-list": _vm.fileList,
                    headers: _vm.uploadHeader,
                    data: _vm.uploadData,
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-upload" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "el-upload__text" }, [
                    _vm._v("Drop file here or "),
                    _c("em", [_vm._v("click to upload")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [_vm._v("jpg/png files with a size less than 500kb")]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("PurchaseRequests.Store") } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: _vm.$t("PurchaseRequests.Stores"),
                    multiple: "",
                    clearable: "",
                  },
                  model: {
                    value: _vm.message.storeIds,
                    callback: function ($$v) {
                      _vm.$set(_vm.message, "storeIds", $$v)
                    },
                    expression: "message.storeIds",
                  },
                },
                _vm._l(_vm.stores, function (store) {
                  return _c("el-option", {
                    key: store.name,
                    attrs: { label: store.name, value: store.uuid },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Role" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "Roles", multiple: "", clearable: "" },
                  model: {
                    value: _vm.message.roleIds,
                    callback: function ($$v) {
                      _vm.$set(_vm.message, "roleIds", $$v)
                    },
                    expression: "message.roleIds",
                  },
                },
                _vm._l(_vm.roles, function (role) {
                  return _c("el-option", {
                    key: role.name,
                    attrs: { label: role.name, value: role.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-success" },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v(_vm._s(_vm.buttonText))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-circle-close" },
                  on: { click: _vm.onCancel },
                },
                [_vm._v(_vm._s(_vm.$t("Order.Cancel")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }