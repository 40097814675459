"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _supplier = require("@/api/supplier");

require("vue-select/src/scss/vue-select.scss");

//
//
//
//
//
//
//
//
//
//
//
// import { getRawMaterials } from '@/api/rawMaterial'
// import i18n from '@/i18n'
var _default = {
  name: 'CopyItemDialog',
  props: {
    items: {
      type: Object,
      default: null
    },
    displayId: {
      type: String,
      default: ''
    },
    supplierId: {
      type: Object,
      default: null
    },
    suppliersId: {
      type: Object,
      required: true
    },
    dialogFormVisible: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      url: 'http://wa.me',
      // items: this.items,
      textarea: '',
      listQuery: {
        page: 1,
        limit: 10,
        listAll: true,
        supplierId: null,
        keyword: null
      },
      phoneNumber: ''
    };
  },

  computed: {
    visible: {
      get() {
        return this.dialogFormVisible;
      },

      set(val) {
        this.$emit('update:dialogFormVisible', val);
      }

    }
  },
  watch: {
    supplier() {
      this.listQuery.supplierId = this.supplierId;
    }

  },

  async created() {
    const items = this.items;
    let text = `PO: ${this.displayId}\r\n` + 'items:\r\n';
    items.forEach(item => {
      text += `${item.qty} ${item.rawMaterial.unit} x ${item.remark} @ ${item.unitPrice}/${item.rawMaterial.unit}\n`;
    });
    this.textarea = text;
    this.listQuery.supplierId = this.supplierId;
    (0, _supplier.getSupplier)(this.supplierId).then(response => {
      this.phoneNumber = response.data.tel;
    });
  },

  methods: {
    shareOnWhatsapp() {
      const message = this.textarea.replace('\r\n', '%0A');
      const url = `https://api.whatsapp.com/send?phone=+${this.phoneNumber}&text=${message}&source=&data=&app_absent=`;
      const win = window.open(url, '_blank');

      if (win) {
        win.focus();
      }
    },

    handleSelect(rawMaterial) {
      this.$emit('complete', {});
      this.$emit('update:dialogFormVisible', false);
    }

  }
};
exports.default = _default;