"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _vuex = require("vuex");

var _errorParse = require("@/utils/error-parse");

var _purchaseOrder = require("@/api/purchaseOrder");

var _purchaseRequest = require("@/api/purchaseRequest");

var _elementUi = require("element-ui");

var _supplier = require("@/api/supplier");

require("vue-select/src/scss/vue-select.scss");

var _store = require("@/api/store");

var _pluralize = _interopRequireDefault(require("pluralize"));

var _vueMultiselect = _interopRequireDefault(require("vue-multiselect"));

var _lodash = _interopRequireDefault(require("lodash.debounce"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import i18n from '@/i18n'
var _default = {
  components: {
    // vSelect,
    Pagination: _Pagination.default,
    Multiselect: _vueMultiselect.default
  },

  /* filters: {
    statusFilter(status) {
      const statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      }
      return statusMap[status]
    }
  }, */
  data() {
    return {
      activeName: null,
      list: null,
      listLoading: true,
      listQuery: {
        listAll: true,
        status: '',
        supplierId: null,
        suppliers: null,
        targetRestockDate: null,
        poDate: null,
        storeId: null,
        page: 1,
        perPage: 10,
        limit: 10,
        search: null,
        fromTime: '',
        toTime: ''
      },
      supplierSelectedName: null,
      total: 0,
      requests: null,
      stores: '',
      statusByOptions: [{
        key: 'drafted',
        name: 'Drafted'
      }, {
        key: 'confirmed',
        name: 'Confirmed'
      }],
      supplierName: null,
      summaryVisible: false,
      summary: null,
      supplierFilter: [],
      options: [],
      hash: null
    };
  },

  computed: {
    device() {
      return this.$store.state.app.device;
    },

    ...(0, _vuex.mapGetters)(['roles']),
    page: {
      get() {
        return this.listQuery.page;
      }

    },
    perPage: {
      get() {
        return this.listQuery.perPage;
      }

    },
    poDateRange: {
      get() {
        return [this.listQuery.fromTime, this.listQuery.toTime];
      },

      set(secondDateOfWeek) {
        if (secondDateOfWeek != null) {
          this.listQuery.fromTime = secondDateOfWeek[0];
          this.listQuery.toTime = secondDateOfWeek[1];
        } else {
          this.listQuery.fromTime = '';
          this.listQuery.toTime = '';
        }
      }

    }
  },
  watch: {
    page() {
      this.pushRouter();
    },

    perPage() {
      this.pushRouter();
    },

    '$i18n.locale'() {
      // Perform the readjustment here
      this.adjustTabs();
    }

  },

  async created() {
    if (this.roles.find(role => ['Warehouse Manager'].includes(role.name)) && !this.roles.find(role => ['Procurement Manager', 'Admin'].includes(role.name)) && (this.$route.params.pathMatch === 'submitted' || this.$route.params.pathMatch === 'drafted')) {
      return this.$router.push(`/purchase-orders/confirmed`);
    } // this.fetchPR()


    if (this.$route.params.pathMatch === 'submitted') {
      for (const key in this.listQuery) {
        if (this.$route.query[key]) {
          if (typeof this.listQuery[key] === 'number') {
            this.listQuery[key] = Number(this.$route.query[key]);
          } else if (key === 'targetRestockDate') {
            const date = new Date(this.$route.query[key]); // Check if the parsed date is valid

            if (isNaN(date)) {
              this.listQuery[key] = null;
            } else {
              const formattedDate = date.toISOString().split('T')[0];
              this.listQuery[key] = formattedDate; // Return the formatted date on success
            }
          } else {
            this.listQuery[key] = this.$route.query[key];
          }
        }
      }

      this.listQuery.status = 'Submitted';
      this.fetchPR();
    } else {
      let targetStatus = null;

      switch (this.$route.params.pathMatch) {
        default:
        case 'all':
          targetStatus = null;
          break;

        case 'drafted':
          targetStatus = 'Drafted';
          break;

        case 'confirmed':
          targetStatus = 'Confirmed';
          break;

        case 'outstanding':
          targetStatus = 'Outstanding';
          break;

        case 'completed':
          targetStatus = 'Completed';
          break;
      }

      this.hash = Math.floor(Math.random() * 100) + 1;
      this.listQuery.status = targetStatus;
      this.fetchData();
    }

    this.activeName = this.$route.params.pathMatch;
  },

  methods: {
    toggleAllEnable() {
      if (this.allEnable) {
        this.list.forEach(row => {
          if (['Submitted', 'Confirmed'].indexOf(row.status) !== -1 && this.filterStatus(row.items, 'pending').length > 0) {
            row.isEnabled = false;
          } else {
            row.isEnabled = false;
          }
        });
        this.allEnable = false;
      } else {
        this.list.forEach(row => {
          if (['Submitted', 'Confirmed'].indexOf(row.status) !== -1 && this.filterStatus(row.items, 'pending').length > 0) {
            row.isEnabled = true;
          } else {
            row.isEnabled = false;
          }
        });
        this.allEnable = true;
      }
    },

    async generateGCFromPurchaseOrder() {
      return _elementUi.MessageBox.confirm('Confirm to cancel Amount?', 'Confirm', {
        confirmButtonText: this.$t('PurchaseOrders.Confirm'),
        cancelButtonText: this.$t('PurchaseOrders.Cancel'),
        type: 'warning'
      }).then(() => (0, _purchaseOrder.editPurchaseOrder)(this.purchaseOrder).then(response => {
        return (0, _purchaseOrder.generateGCFromPurchaseOrder)(this.purchaseOrder.uuid).then(() => {
          (0, _elementUi.Message)({
            message: this.$t('PurchaseOrders.GCGenerated'),
            type: 'success',
            duration: 5 * 1000
          });
          this.$router.push('/good-received-notes/edit/' + response.data.uuid);
        }).catch(err => {
          (0, _elementUi.Message)({
            dangerouslyUseHTMLString: true,
            message: (0, _errorParse.parseError)(err) || this.$t('PurchaseOrders.GCFailed'),
            type: 'error',
            duration: 5 * 1000
          });
        });
      }).catch(err => {
        (0, _elementUi.Message)({
          dangerouslyUseHTMLString: true,
          message: (0, _errorParse.parseError)(err) || this.$t('PurchaseOrders.GCFailed'),
          type: 'error',
          duration: 5 * 1000
        });
      })).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },

    adjustTabs() {
      this.$nextTick(() => {
        if (this.$refs.tabs) {
          this.$refs.tabs.calcPaneInstances(true);
        }
      });
    },

    previewData() {
      const purchaseData = this.list; // const ids = ''

      const selectedPurchaseOrders = [];
      purchaseData.forEach(p => {
        if (p.isEnabled) {
          selectedPurchaseOrders.push(p);
        }
      });
      this.summary = {};
      this.options = [];
      this.supplierFilter = [];
      let hasItem = false;
      selectedPurchaseOrders.forEach(purchaseOrder => {
        var pr = purchaseOrder.uuid;
        purchaseOrder.items.forEach(item => {
          if (item.status !== 'pending') {
            return;
          }

          let supplierId = -1;

          if (item.rawMaterial.supplier) {
            supplierId = item.rawMaterial.supplier.uuid;
          }

          if (!this.summary[supplierId]) {
            this.summary[supplierId] = {
              id: supplierId,
              uuid: item.rawMaterial.supplier.uuid,
              name: item.rawMaterial.supplier ? item.rawMaterial.supplier.name : 'No Name',
              shortName: item.rawMaterial.supplier ? item.rawMaterial.supplier.shortName ? item.rawMaterial.supplier.shortName : item.rawMaterial.supplier.name : 'No Name',
              items: {}
            };
            this.options.push({
              uuid: this.summary[supplierId].uuid,
              name: this.summary[supplierId].name
            });
          }

          if (!this.summary[supplierId].items[item.rawMaterial.uuid]) {
            this.summary[supplierId].items[item.rawMaterial.uuid] = {
              sku: item.rawMaterial.sku,
              name: item.rawMaterial.nameEng,
              unit: item.rawMaterial.unit,
              unitPrice: item.rawMaterial.defaultPrice,
              qty: 0
            };
          }

          this.summary[supplierId].items[item.rawMaterial.uuid].qty += item.qty;
          this.summary[supplierId].items[item.rawMaterial.uuid].status = item.status; // must pending

          if (!this.summary[supplierId].purchaseRequest) {
            this.summary[supplierId].purchaseRequest = [];
          }

          this.summary[supplierId].purchaseRequest.push(pr);
          hasItem = true;
        });
      });

      for (const i in this.summary) {
        this.summary[i].items = Object.values(this.summary[i].items);
        this.summary[i].total = 0;
        this.summary[i].items.forEach(item => {
          this.summary[i].total += (item.unitPrice ? item.unitPrice.toFixed(2) : 0) * item.qty;
        });
      }

      if (hasItem) {
        this.summaryVisible = true;
      } else {
        this.$alert(this.$t('No Items, Please Select Item First'), 'Preview').catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
      }
    },

    pushRouter() {
      if (this.$route.params.pathMatch === 'submitted') {
        this.$router.push({
          query: Object.keys(this.listQuery).filter(key => ['targetRestockDate', 'storeId', 'search', 'page', 'perPage'].includes(key) && this.listQuery[key] !== null).reduce((obj, key) => ({ ...obj,
            [key]: this.listQuery[key]
          }), {})
        });
      } // PO params are different

    },

    query() {
      this.pushRouter();
      this.fetchPR();
    },

    pluralize(unit) {
      if (['kg', 'gr', 'l', 'ml', 'mg'].indexOf(unit) === -1) {
        return (0, _pluralize.default)(unit);
      } else {
        return unit;
      }
    },

    async fetchSupplier(queryString, cb) {
      let keyword = queryString;

      if (queryString === this.supplierSelectedName) {
        keyword = null;
      }

      (0, _lodash.default)(search => {
        const params = {
          perPage: 20,
          search
        };

        if (this.$route.params.pathMatch === 'submitted') {
          params.hasPurchaseRequestsOutstanding = 'true'; // params.hash = this.hash
        } else if (this.listQuery.status) {
          params.purchaseOrderStatus = this.listQuery.status;
        }

        (0, _supplier.getSuppliers)(params).then(response => cb(response.data.map(supplier => ({
          label: supplier.name,
          value: supplier.uuid
        }))));
      }, 880)(keyword);
    },

    clearSupplier() {
      this.listQuery.supplierId = null;
      this.listQuery.suppliers = null;
      this.supplierSelectedName = null;
    },

    checkSupplier() {
      if (this.supplierSelectedName !== this.supplierName) {
        this.supplierName = this.supplierSelectedName;
      }
    },

    handleSelectSupplier(item) {
      this.listQuery.supplierId = item.value;
      this.listQuery.suppliers = item.value;
      this.supplierSelectedName = item.label;
    },

    getSupplierName(items) {
      var supplier = [];
      items.map(item => {
        if (item.rawMaterial && item.rawMaterial.supplier) {
          if (supplier.indexOf(item.rawMaterial.supplier.name) === -1) {
            supplier.push(item.rawMaterial.supplier.name);
          }
        }
      });
      return supplier.join();
    },

    async fetchData() {
      this.listLoading = true;
      (0, _purchaseOrder.getList)(Object.entries(this.listQuery).reduce((acc, [key, value]) => {
        if (value !== '') {
          acc[key] = value;
        }

        return acc;
      }, {})).then(response => {
        this.list = response.data;
        this.total = response.meta.total;
      }).finally(() => {
        this.listLoading = false;
      });
    },

    async fetchPR() {
      this.listLoading = true;
      (0, _purchaseRequest.getList)(Object.entries(this.listQuery).reduce((acc, [key, value]) => {
        if (value !== '') {
          acc[key] = value;
        }

        return acc;
      }, {})).then(response => {
        this.list = response.data.map(row => {
          row.isEnabled = false;
          return row;
        });
        this.total = response.meta.total;
      }).finally(() => {
        this.listLoading = false;
      });

      if (window.requestIdleCallback) {
        requestIdleCallback(() => {
          (0, _store.getStores)({
            limit: 999
          }).then(response => {
            this.stores = response.data;
          });
        });
      } else {
        (0, _store.getStores)({
          limit: 999
        }).then(response => {
          this.stores = response.data;
        });
      }
    },

    deletePurchaseRequest(id) {
      return _elementUi.MessageBox.confirm('Confirm to delete record?', 'Confirm', {
        confirmButtonText: this.$t('PurchaseRequests.Delete'),
        cancelButtonText: this.$t('PurchaseRequests.Cancel'),
        type: 'warning'
      }).then(() => (0, _purchaseRequest.deletePurchaseRequest)(id).catch(err => err.status === 404 ? Promise.resolve('already deleted') : (0, _elementUi.Message)({
        dangerouslyUseHTMLString: true,
        message: (0, _errorParse.parseError)(err) || 'Delete failed, please retry.',
        type: 'error',
        duration: 5 * 1000
      })).then(() => {
        (0, _elementUi.Message)({
          message: this.$t('PurchaseRequests.RecordDeleted'),
          type: 'success',
          duration: 5 * 1000
        }); // getListRequests(this.listQuery)

        if (this.$workbox && this.$workbox.active instanceof Promise) {
          const uniqueValue = window['Symbol'] ? Symbol('unique') : /unique/;
          return Promise.race([this.$workbox.active, Promise.resolve(uniqueValue)]).then(value => {
            if (value !== uniqueValue) {
              return this.fetchPR().then(() => this.fetchPR());
            } else {
              return this.fetchPR();
            }
          }, () => {
            return this.fetchPR();
          });
        } else {
          return this.fetchPR();
        }
      })).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },

    async rejectPurchaseRequest(uuid) {
      return _elementUi.MessageBox.confirm(this.$t('PurchaseRequests.ConfirmRejectRequest').toString(), 'Confirm', {
        confirmButtonText: this.$t('PurchaseRequests.Reject'),
        cancelButtonText: this.$t('PurchaseRequests.Cancel'),
        type: 'warning'
      }).then(() => (0, _purchaseRequest.rejectPurchaseRequest)(uuid).then(() => {
        (0, _elementUi.Message)({
          message: this.$t('PurchaseRequests.RequestRejected'),
          type: 'success',
          duration: 5 * 1000
        });
        (0, _purchaseRequest.getList)(this.listQuery);
        return this.$router.go(0);
      }).catch(err => {
        (0, _elementUi.Message)({
          message: (0, _errorParse.parseError)(err) || this.$t('PurchaseRequests.RejectRequestFailed'),
          type: 'error',
          duration: 5 * 1000
        });
      })).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },

    generatePO() {
      return _elementUi.MessageBox.confirm(this.$t('PurchaseRequests.ConfirmToGeneratePO'), 'Confirm', {
        confirmButtonText: this.$t('PurchaseRequests.Generate'),
        cancelButtonText: this.$t('PurchaseRequests.Cancel'),
        type: 'warning'
      }).then(() => {
        const uuids = [];
        this.list.forEach(row => {
          if (row.isEnabled) {
            uuids.push(row.uuid);
          }
        });
        return (0, _purchaseRequest.generatePurchaseOrderFromPurchaseRequest)(uuids, this.supplierFilter).then(response => {
          for (const item of response.data) {
            (0, _elementUi.Message)({
              message: this.$t('PurchaseRequests.POGenerated') + item.displayId,
              type: 'success',
              duration: 5 * 1000
            });
          }

          (0, _purchaseOrder.getList)(this.listQuery);
          this.$router.push(`/purchase-orders/confirmed`);
        }).catch(response => {
          (0, _elementUi.Message)({
            message: this.$t('PurchaseRequests.CantGeneratePOGenerated') + ' ' + (response.message ? response.message : ''),
            type: 'danger',
            duration: 5 * 1000
          });
        });
      }).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },

    generatePOByStore() {
      return _elementUi.MessageBox.confirm(this.$t('PurchaseRequests.ConfirmToGeneratePOByStore'), 'Confirm', {
        confirmButtonText: this.$t('PurchaseRequests.Generate'),
        cancelButtonText: this.$t('PurchaseRequests.Cancel'),
        type: 'warning'
      }).then(() => {
        const uuids = [];
        this.list.forEach(row => {
          if (row.isEnabled) {
            uuids.push(row.uuid);
          }
        });
        return (0, _purchaseRequest.generatePurchaseOrderByStoreFromPurchaseRequest)(uuids, this.supplierFilter).then(response => {
          for (const item of response.data) {
            (0, _elementUi.Message)({
              message: this.$t('PurchaseRequests.POGenerated') + item.displayId,
              type: 'success',
              duration: 5 * 1000
            });
          }

          (0, _purchaseOrder.getList)(this.listQuery);
          this.$router.push(`/purchase-orders/confirmed`);
        }).catch(response => {
          (0, _elementUi.Message)({
            message: this.$t('PurchaseRequests.CantGeneratePOGenerated') + ' ' + (response.message ? response.message : ''),
            type: 'danger',
            duration: 5 * 1000
          });
        });
      }).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },

    checkDeliveryAdress(id) {
      const purchaseOrder = this.list.find(purchaseOrder => purchaseOrder.uuid === id);

      if (!purchaseOrder.deliveryAddress && purchaseOrder.status === 'Confirmed') {
        return _elementUi.MessageBox.confirm('This PO doesn\'t contain delivery Address (You can add in in detail page).<br />Do you still want to download?', 'Confirm', {
          confirmButtonText: this.$t('Supplier.Confirm'),
          cancelButtonText: this.$t('PurchaseOrders.Cancel'),
          dangerouslyUseHTMLString: true,
          type: 'warning'
        }).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
      }

      return Promise.resolve();
    },

    async downloadPurchaseOrder(id, po) {
      this.checkDeliveryAdress(id).then(() => (0, _purchaseOrder.downloadPurchaseOrder)(id)).then(response => {
        const fileURL = window.URL.createObjectURL(response);
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `${po.displayId}${po.supplier.shortname ? ' - ' + po.supplier.shortname : ' -'} ${po.stores === 'Moo Moo Plus' ? 'CAUSEWAY BAY' : po.stores === 'Kwai Chung Deliveroo Kitchen - Yee Siu / Spicy Master' ? '' : 'KWUN TONG'}${' - ' + po.stores}.pdf`);
        document.body.appendChild(fileLink);
        fileLink.click();
      }).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },

    deletePurchaseOrder(id) {
      return _elementUi.MessageBox.confirm(this.$t('PurchaseOrders.ConfirmDelete'), 'Confirm', {
        confirmButtonText: this.$t('PurchaseOrders.Delete'),
        cancelButtonText: this.$t('PurchaseOrders.Cancel'),
        type: 'warning'
      }).then(() => (0, _purchaseOrder.deletePurchaseOrder)(id).catch(err => err.status === 404 ? Promise.resolve('already deleted') : (0, _elementUi.Message)({
        dangerouslyUseHTMLString: true,
        message: (0, _errorParse.parseError)(err) || 'Delete failed, please retry.',
        type: 'error',
        duration: 5 * 1000
      })).then(() => {
        (0, _elementUi.Message)({
          message: this.$t('PurchaseOrders.Deleted'),
          type: 'success',
          duration: 5 * 1000
        });

        if (this.$workbox && this.$workbox.active instanceof Promise) {
          const uniqueValue = window['Symbol'] ? Symbol('unique') : /unique/;
          return Promise.race([this.$workbox.active, Promise.resolve(uniqueValue)]).then(value => {
            if (value !== uniqueValue) {
              return this.fetchData().then(() => this.fetchData());
            } else {
              return this.fetchData();
            }
          }, () => {
            return this.fetchData();
          });
        } else {
          return this.fetchData();
        }
      })).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },

    generateGRFromPurchaseOrder(uuid) {
      return _elementUi.MessageBox.confirm(this.$t('PurchaseOrders.ConfirmGenerateGR'), 'Confirm', {
        confirmButtonText: this.$t('PurchaseOrders.Confirm'),
        cancelButtonText: this.$t('PurchaseOrders.Cancel'),
        type: 'warning'
      }).then(() => (0, _purchaseOrder.generateGRFromPurchaseOrder)(uuid).then(response => {
        (0, _elementUi.Message)({
          dangerouslyUseHTMLString: true,
          message: this.$t('PurchaseOrders.GRGenerated'),
          type: 'success',
          duration: 5 * 1000
        });
        return this.$router.push('/good-received-notes/edit/' + response.data.uuid);
      }).catch(err => {
        (0, _elementUi.Message)({
          dangerouslyUseHTMLString: true,
          message: (0, _errorParse.parseError)(err) || this.$t('PurchaseOrders.GRFailed'),
          type: 'error',
          duration: 5 * 1000
        });
      })).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },

    /* gotoGoodReceived(uuid) {
      this.$router.push(`/good-received-notes/edit/` + uuid)
    }, */
    handleTabClick(tab) {
      const targetStatus = this.listQuery.status;

      if (targetStatus.toLowerCase() !== tab.name) {
        this.$router.push(`/purchase-orders/` + tab.name);
      }
    },

    filterSupplier(arr, query) {
      return arr.filter(function (el) {
        return el.uuid === query;
      });
    },

    filterStatus(arr, query) {
      return arr.filter(function (el) {
        return el.status === query;
      });
    }

  }
};
exports.default = _default;