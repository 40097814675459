"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _vuex = require("vuex");

var _moomoomember = require("@/api/moomoomember");

var _elementUi = require("element-ui");

var _vueQrReader = _interopRequireDefault(require("vue-qr-reader/dist/lib/vue-qr-reader.umd"));

var _errorParse = require("@/utils/error-parse");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    VueQrReader: _vueQrReader.default
  },

  /* filters: {
    statusFilter(status) {
      const statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      }
      return statusMap[status]
    }
  }, */
  data() {
    return {
      list: null,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10
      },
      total: 0,
      showQRScan: false,
      QRScannerAbility: true
    };
  },

  computed: { ...(0, _vuex.mapGetters)([// 'name',
    'user'])
  },

  async created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      this.listLoading = true;
      (0, _moomoomember.getMOOMOOMembers)(this.listQuery).then(response => {
        this.list = response.data;
        this.total = response.meta.total;
      }).finally(() => {
        this.listLoading = false;
      });
    },

    getMOOMOOMember(id) {
      this.$router.push(`/moomoomembers/show/${id}`);
    },

    editPasskitPoint(id) {
      this.$router.push(`/moomoomembers/point/${id}`);
    },

    setActive(id) {
      return _elementUi.MessageBox.confirm('Confirm to Activate Moo Moo Member?', 'Confirm', {
        confirmButtonText: 'Activate',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => (0, _moomoomember.activateMOOMOOMember)(id).then(() => {
        (0, _elementUi.Message)({
          message: 'Moo Moo Member Activated',
          type: 'success',
          duration: 5 * 1000
        });
        return this.fetchData();
      })).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },

    setNotActive(id) {
      return _elementUi.MessageBox.confirm('Confirm to Deactivate Moo Moo Member?', 'Confirm', {
        confirmButtonText: 'Deactivate',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => (0, _moomoomember.deactivateMOOMOOMember)(id).then(() => {
        (0, _elementUi.Message)({
          message: 'Moo Moo Member Deactivated',
          type: 'success',
          duration: 5 * 1000
        });
        this.fetchData();
      })).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },

    async deleteMOOMOOMember(id) {
      return _elementUi.MessageBox.confirm('Confirm to delete Moo Moo Member?', 'Confirm', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => (0, _moomoomember.deleteMOOMOOMember)(id).catch(err => err.status === 404 ? Promise.resolve('already deleted') : Promise.reject(err)).then(() => {
        (0, _elementUi.Message)({
          message: 'Moo Moo Member deleted',
          type: 'success',
          duration: 5 * 1000
        });
        this.fetchData();
      })).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },

    ShowQRScanner() {
      this.showQRScan = true;
    },

    codeArrived(decodedString) {
      console.info(decodedString);
      const url = decodedString.split('/', 4).pop();

      if (url) {
        this.showQRScan = false;
        this.listLoading = true;
        (0, _moomoomember.getMOOMOOMembers)({
          passkit: url,
          limit: 1
        }).then(response => {
          this.list = response.data;

          if (Array.isArray(response.data) && response.data.length) {
            this.$router.push(`/moomoomembers/point/${response.data[0].id}`);
          } else {
            (0, _elementUi.Message)({
              message: 'Scan fail',
              type: 'error',
              duration: 3 * 1000
            });
          }
        }).catch(err => {
          (0, _elementUi.Message)({
            message: (0, _errorParse.parseError)(err) || 'Scan fail, code not found',
            type: 'error',
            duration: 5 * 1000
          }); // console.log(err)
        }).finally(() => {
          this.listLoading = false;
        });
      }
    },

    errorCaptured(error) {
      this.QRScannerAbility = false;
      console.error(error);
    }

  }
};
exports.default = _default;